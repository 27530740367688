/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import DataTable from "react-data-table-component";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Select from "react-select";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Tooltip } from "@material-tailwind/react";

/* ICONS */
import { BiEdit } from "react-icons/bi";
import { FiTrash } from "react-icons/fi";
import { IoBan, IoEyeSharp } from "react-icons/io5";

/* DATA */

/* USER DEFINED COMPONENTS */
import Input from "../../../components/input";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* API IMPORT */
import { IMAGE_URL } from "../../../config";
import { deleteProduct, getProductList } from "../../../service/api";

/* CATEGORIES */
const categoryOptions = [
  {
    label: "Breakfast, cereals and premixes",
    value: "Breakfast, cereals and premixes",
  },
  {
    label: "Chocolate, cookies and candies",
    value: "Chocolate, cookies and candies",
  },
  {
    label: "Grains, legumes and flours",
    value: "Grains, legumes and flours",
  },
  {
    label: "Healthy Snacks",
    value: "Healthy Snacks",
  },
];

/* STATUS */
const status = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

const Inventory = () => {
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(30);

  const [productsList, setProductsList] = useState([]);
  console.log("🚀 ~ Inventory ~ productsList:", productsList)

  const [totalDocs, setTotalDocs] = useState([]);

  const [search, setSearch] = useState("");

  const [deleteId, setDeleteId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  /* FUNCTIONS */
  const handlePageChange = async (e) => {
    setPage(e);
    getProductsListFunction();
  };

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
    getProductsListFunction();
  };

  const getProductsListFunction = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: sizePerPage,
      // pagination: true,
    };
    if (search) {
      params.search = search;
    }

    getProductList(params)
      .then((res) => {
        setProductsList(res?.data?.docs);
        setTotalDocs(res?.data?.totalDocs);
      })
      .catch((e) => {
        console.log("e-->", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /* HANDLE DELETE DATA */
  const handleDelete = () => {
    deleteProduct(deleteId)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setDeleteModal(!deleteModal);
        setDeleteId(null);
        getProductsListFunction();
      });
  };

  /* TABLE COLUMNS */
  const columns = [
    {      //* TODO: Yaha image aaega kya ?? Kyunki Image bahar present nahi hai, quantity[] ka images hai 
      name: "Image",
      width: "15vh",
      selector: (row) => (
        <span>
          <img
            src={
              IMAGE_URL + row?.quantity[0]?.image?.front?.url
            }
            className="w-20 h-20 rounded-lg object-cover"
            alt={row.name}
          />
        </span>
      ),
    },
    {
      name: "Name",
      width: "40vh",
      selector: (row) => (
        <span className="text-sm font-proxima-bold text-black">{row.name}</span>
      ),
    },
    // {
    //   name: "SKU",
    //   width: "20vh",
    //   selector: (row) => (
    //     <span className="text-sm font-proxima-semibold text-black text-opacity-60">
    //       {row.sku}
    //     </span>
    //   ),
    // },
    {
      name: "Category",
      width: "30vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row?.category?.map((element, index) => {

            return index === 0 ? (
              <span key={index}>{element?.name}</span>
            ) : (
              <span key={index}>{", " + element?.name}</span>
            );
          })}
        </span>
      ),
    },
    {
      name: "Tag",
      width: "20vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row?.tag?.map((element, index) => {

            return index === 0 ? (
              <span key={index}>{element?.name}</span>
            ) : (
              <span key={index}>{", " + element?.name}</span>
            );
          })}
        </span>
      ),
    },
    // {       // TODO: Quantity ek array me aarha hai isko kaise display karenge ?
    //   name: "Quantity",
    //   width: "20vh",
    //   selector: (row) => (
    //     <span className="text-sm font-proxima-bold text-black">
    //       {row.quantity}
    //     </span>
    //   ),
    // },
    {        
      name: "Visibility",
      width: "20vh",
      selector: (row) => (
        <span
          className={
            row?.status === "active"
              ? "text-sm font-proxima-bold text-green-800 text-opacity-60"
              : "text-sm font-proxima-bold text-yellow-700 text-opacity-60"
          }
        >
          {row.status === "active" ? "Enabled" : "Disabled"}
        </span>
      ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center">
          <Tooltip
            content="View product details"
            className="text-sm font-proxima-semibold"
          >
            <Link to={"/inventory/view-product/" + row?._id}>
              <Button className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
                <IoEyeSharp className="w-5 h-5 text-greyText" />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip
            content="Edit product details"
            className="text-sm font-proxima-semibold"
          >
            <Link to={"/inventory/edit-product/" + row?._id}>
              <Button className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
                <BiEdit className="w-5 h-5 text-greyText" />
              </Button>
            </Link>
          </Tooltip>
          <Tooltip
            content="Remove product"
            className="text-sm font-proxima-semibold"
          >
            <Button className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
              <FiTrash className="w-5 h-5 text-greyText" />
            </Button>
          </Tooltip>
          <Tooltip
            content="Disable on website"
            className="text-sm font-proxima-semibold"
          >
            <Button className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
              <IoBan className="w-5 h-5 text-greyText" />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  /* USE-EFFECTS */
  useEffect(() => {
    getProductsListFunction();
  }, [page, sizePerPage, search]);

  /* LOADING SCREEN FOR DATATABLE */
  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-sm font-proxima-semibold text-black text-center">
          We are fetching data{" "}
        </p>
      </div>
    );
  };

  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          There are no queries to display.
        </p>
      </div>
    );
  };
  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Inventory | Fashion N Fun</title>
      </Helmet>

      <div className="container-full-width">
        <div className="block xl:flex lg:flex items-center justify-between">
          {/* HEADING */}
          <div>
            <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
              Inventory
            </h1>
            <h2 className="text-lg font-proxima-regular text-greyText tracking-tight">
              Manage the product control on your website
            </h2>
          </div>

          {/* SEARCH AND FILTERS */}
          <div className="block xl:flex lg:flex items-center gap-2">
            {/* SEARCHBAR */}
            <div className="w-full xl:w-searchBar lg:w-searchBar mt-2 xl:mt-0 lg:mt-0">
              <Input
                type="text"
                placeholder="Search from your inventory"
                name="searchInv"
                id="searchInv"
              />
            </div>

            {/* CATEGORY DROPDOWN */}
            <div className="w-full xl:w-categorySelect lg:w-categorySelect mt-2 xl:mt-0 lg:mt-0">
              <Select
                options={categoryOptions}
                classNamePrefix="dropdown"
                placeholder="Select category"
                name="category"
                id="category"
              />
            </div>

            {/* STATUS DROPDOWN */}
            <div className="w-full xl:w-statusSelect lg:w-statusSelect mt-2 xl:mt-0 lg:mt-0">
              <Select
                options={status}
                classNamePrefix="dropdown"
                placeholder="Select status"
                name="status"
                id="status"
              />
            </div>

            {/* ADD PRODUCT */}
            <Link to="/inventory/add-product">
              <Button className="w-full xl:w-auto lg:w-auto mt-2 xl:mt-0 lg:mt-0 bg-logoYellow rounded-lg py-3 px-8 text-sm font-proxima-semibold shadow-none hover:shadow-none normal-case hover:bg-opacity-80 transition-all duration-300 ease-in-out">
                Add Product
              </Button>
            </Link>
          </div>
        </div>

        {/* INVENTORY TABLE */}
        <div className="mt-5">
          <DataTable
            className="data-table"
            columns={columns}
            data={totalDocs > 0 ? productsList : []}
            progressPending={loading}
            progressComponent={<LoadingScreen />}
            pagination
            paginationServer
            paginationTotalRows={totalDocs}
            onChangeRowsPerPage={handlePerPageChange}
            onChangePage={handlePageChange}
            noDataComponent={<NoDataScreen />}
          />
        </div>
      </div>
    </section>
  );
};

export default Inventory;
