/* REACT IMPORTS */
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

/* STYLES */
import "../styles/global.css";

/* LAYOUT */
import Layout from "../layout";

/* PAGES */
import AuthLogin from "../pages/auth/login";
import AuthRegister from "../pages/auth/register";
import Dashboard from "../pages/dashboard";
import Orders from "../pages/dashboard/orders";
import OrderDetails from "../pages/dashboard/orders/order-details/";
import InvoiceDetails from "../pages/dashboard/orders/invoice";
import Customers from "../pages/dashboard/customers";
import CustomerDashboard from "../pages/dashboard/customers/customer-dashboard";
import Inventory from "../pages/dashboard/inventory";
import AddProduct from "../pages/dashboard/inventory/add-product";
import ViewProduct from "../pages/dashboard/inventory/view-product";
import EditProduct from "../pages/dashboard/inventory/edit-product";
import Categories from "../pages/dashboard/categories";
import SubCategories from "../pages/dashboard/sub-categories";
import Tags from "../pages/dashboard/tags";
import OffersAndPromocodes from "../pages/dashboard/offers-and-promocodes";
import ContactUs from "../pages/dashboard/contact-us";
import ErrorPage from "../pages/error/404";

/* SCROLL TO TOP */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRoutes = () => {
  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Routes>

          <Route path="/" element={<AuthLogin />} />
          <Route path="/register" element={<AuthRegister />} />
          <Route
            path="/dashboard"
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
          <Route
            path="/orders"
            element={
              <Layout>
                <Orders />
              </Layout>
            }
          />
          <Route
            path="/order/:id"
            element={
              <Layout>
                <OrderDetails />
              </Layout>
            }
          />
          <Route
            path="/invoice/:id"
            element={
              <InvoiceDetails />
            }
          />
          <Route
            path="/customers"
            element={
              <Layout>
                <Customers />
              </Layout>
            }
          />
          <Route
            path="/customers/:id"
            element={
              <Layout>
                <CustomerDashboard />
              </Layout>
            }
          />

          <Route
            path="/inventory"
            element={
              <Layout>
                <Inventory />
              </Layout>
            }
          />
          <Route
            path="/inventory/add-product"
            element={
              <Layout>
                <AddProduct />
              </Layout>
            }
          />
          <Route
            path="/inventory/view-product/:id"
            element={
              <Layout>
                <ViewProduct />
              </Layout>
            }
          />
          <Route
            path="/inventory/edit-product/:id"
            element={
              <Layout>
                <EditProduct />
              </Layout>
            }
          />
          <Route
            path="/categories"
            element={
              <Layout>
                <Categories />
              </Layout>
            }
          />
          <Route
            path="/sub-categories"
            element={
              <Layout>
                <SubCategories />
              </Layout>
            }
          />
          <Route
            path="/tags"
            element={
              <Layout>
                <Tags />
              </Layout>
            }
          />
          <Route
            path="/offers-and-promocodes"
            element={
              <Layout>
                <OffersAndPromocodes />
              </Layout>
            }
          />
          <Route
            path="/contact-us"
            element={
              <Layout>
                <ContactUs />
              </Layout>
            }
          />

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;
