/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import ReactImageUploading from "react-images-uploading";
import ReactPaginate from "react-paginate";
import Select from "react-select";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Chip,
  Dialog,
  DialogBody,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";

/* ICONS */
import { AiFillPlusCircle } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";
import { GrNext, GrPrevious } from "react-icons/gr";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoClose } from "react-icons/io5";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* REDUX IMPORTS */
import { useSelector } from "react-redux";

/* FORM CONTROL */
import { useFormik } from "formik";
import * as yup from "yup";

/* IMPORTED APIs */
import { addSubCategory, deleteSubCategory, editSubCategoryDetailsById, getSubCategoryList } from "../../../service/api";

/* USER DEFINED COMPONENTS */
import Input from "../../../components/input";
import { IMAGE_URL } from "../../../config";

/* STATUS */
const status = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 0 },
  { label: "Clear Filters", value: "clear" },
];

/* YES OR NO OPTIONS */
const options = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];


/* CATEGORIES */
const categoryOptions = [
  {
    label: "Shop by Food",
    value: "Shop by Food",
  },
  {
    label: "Shop by Fluids",
    value: "Shop by Fluids",
  },
  {
    label: "Shop by Farmacy",
    value: "Shop by Farmacy",
  },
];

/* Validation Schema */
// Validation Schema For Category Add Form
const validationSchemaForSubCategoryAdd = yup.object().shape({
  category: yup.object().required("Category is required."),
  name: yup.string().required("Sub-Category Name is required."),
  shortDescription: yup.string(),
  description: yup.mixed(),
  isMenu: yup.object().required("Menu Status Required"),
  isFeatured: yup.object().required("Featured Status Required"),
});

const SubCategories = () => {

  // GLOBAL VARIABLES
  const userDetails = useSelector((state) => state?.user);

  // STATE VARIABLES 
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [subCategoryList, setSubCategoryList] = useState([]);

  const [totalDocs, setTotalDocs] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const [search, setSearch] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const [subCategoryImages, setSubCategoryImages] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState([])

  const [categoryList, setCategoryList] = useState([]);

  /* MODAL STATES */
  const [addDialog, setAddDialog] = useState(false);
  const [addSubCategoryFormLoading, setAddSubCategoryFormLoading] = useState(false);

  const [editDialog, setEditDialog] = useState(false);
  const [editId, setEditId] = useState(null);
  const [editSubCategoryFormLoading, setEditSubCategoryFormLoading] = useState(false);

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const [viewDialog, setViewDialog] = useState(false);
  const [viewDetails, setViewDetails] = useState(null);

  /* CATEGORY BOX */
  const CategoryBox = (props) => {
    console.log("props", props)
    return (
      <div className="bg-white rounded-lg border border-authBorder">
        <div className="p-0">
          <div className="flex items-start justify-between p-5 pb-0">
            <div>
              <h5 className="text-lg font-proxima-bold text-black ">
                {props.name}
              </h5>
            </div>
            <Menu placement="right">
              <MenuHandler>
                <Button className="p-[0.5vh] shadow-none hover:shadow-none bg-white rounded-full border border-authBorder hover:border-logoYellow transition-all duration-300 ease-in-out">
                  <HiOutlineDotsVertical className="w-4 h-4 text-black" />
                </Button>
              </MenuHandler>
              <MenuList className="p-2">
                <MenuItem
                  className="text-sm font-proxima-semibold text-black"
                  onClick={() => setEditDialog(!editDialog)}
                >
                  Edit category
                </MenuItem>
                <MenuItem
                  className="text-sm font-proxima-semibold text-black"
                  onClick={() => { setViewDetails(props.details); setViewDialog(!viewDialog) }}
                >
                  View category
                </MenuItem>


                <MenuItem
                  className="text-sm font-proxima-semibold text-black"
                  onClick={() => {
                    setDeleteId(props.id)
                    setDeleteDialog(!deleteDialog)
                  }}
                >
                  Delete category
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
          <div className="p-5">
            <p className="text-sm font-proxima-regular text-greyText">
              Do you want to enable this on website?
            </p>
            <p className="text-xs font-proxima-regular text-greyText italic">
              (By default, it will be enabled)
            </p>
            <div className="flex items-center gap-2 mt-2">
              {props?.enabled ? (
                <Button className="shadow-none hover:shadow-none px-4 py-2 rounded-md border border-green-500 bg-green-500 transition-all duration-300 ease-in-out text-white font-proxima-semibold text-xs normal-case leading-none font-medium">
                  Enabled
                </Button>
              ) : (
                <Button
                  className="shadow-none hover:shadow-none px-4 py-2 rounded-md bg-white border border-authBorder hover:bg-green-500 hover:bg-opacity-10 hover:border-green-500 transition-all duration-300 ease-in-out text-black hover:text-green-500 font-proxima-semibold text-xs normal-case leading-none font-medium"
                  onClick={() => enableCategory(props.details)}
                >
                  Enable
                </Button>
              )}

              {!props?.enabled ? (
                <Button className="shadow-none hover:shadow-none px-4 py-2 rounded-md border border-red-500 bg-red-500 transition-all duration-300 ease-in-out text-white font-proxima-semibold text-xs normal-case leading-none font-medium">
                  Disabled
                </Button>
              ) : (
                <Button
                  className="shadow-none hover:shadow-none px-4 py-2 rounded-md bg-white border border-authBorder hover:bg-red-500 hover:bg-opacity-10 hover:border-red-500 transition-all duration-300 ease-in-out text-black hover:text-red-500 font-proxima-semibold text-xs normal-case leading-none font-medium"
                  onClick={() => disableCategory(props.details)}
                >
                  Disable
                </Button>
              )}
            </div>
          </div>
          <div className="border-t border-authBorder py-3 px-5">
            <div className="flex items-center justify-between">
              <p className="text-sm font-proxima-regular text-greyText">
                Assigned category:
              </p>
              <Chip
                value={props?.details?.category?.name}
                className="text-sm font-proxima-regular text-white tracking-tight normal-case px-3 py-1 rounded-md"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };


  // Formik Function For Add Sub Category
  const addSubCategoryFormik = useFormik({
    initialValues: {
      category: "",
      name: "",
      shortDescription: "",
      description: "",
      isMenu: { label: "No", value: "no" },
      isFeatured: { label: "No", value: "no" },
    },
    validationSchema: validationSchemaForSubCategoryAdd,
    onSubmit: (values) => {
      console.log(values)
      const formData = new FormData();
      formData.append("category", values?.category?.value);
      formData.append("name", values?.name);
      formData.append("isMenu", values?.isMenu?.value === "yes" ? true : false);
      formData.append("isFeatured", values?.isFeatured?.value === "yes" ? true : false);
      if (values?.description) {
        formData.append("description", draftToHtml(convertToRaw(values?.description?.getCurrentContent())));
      }
      if (subCategoryImages?.[0]?.file) {
        formData.append("image", subCategoryImages?.[0]?.file);
      }
      for (const entry of formData.entries()) {
        console.log(`${entry[0]}: ${entry[1]}`);
      }
      setAddSubCategoryFormLoading(true);
      addSubCategory(formData)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            addSubCategoryFormik.handleReset();
            setSubCategoryImages([])
            setAddDialog(false)
          } else {
            console.log(res?.message);
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setAddSubCategoryFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          subCategoriesGetFunction();
          setAddSubCategoryFormLoading(false);
        });
    }
  })

  // Functions
  const subCategoriesGetFunction = () => {
    const obj = {
      page,
      sizePerPage,
      pagination: true
    };
    if (search) {
      obj.search = search;
    }
    if (selectedStatus) {
      obj.status = selectedStatus?.value;
    }
    if (selectedCategory) {
      let tempArray = []
      selectedCategory?.forEach((e) => {
        tempArray.push(e?.value)
      })
      obj.category = tempArray
    }
    setLoading(true);
    getSubCategoryList(obj)
      .then((response) => {
        if (response?.status) {
          setSubCategoryList(response?.data?.docs);
          setTotalDocs(response?.data?.totalDocs);
          setTotalPages(response?.data?.totalPages);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const categoryGetFunction = () => {
    const obj = {
      page,
      sizePerPage,
      pagination: false
    };

    setLoading(true);
    getSubCategoryList(obj)
      .then((response) => {
        if (response?.status) {
          let tempArray = []
          response?.data?.docs?.forEach((element) => {          //TODO: ERROR
            tempArray.push({ value: element?._id, label: element?.name })
          })
          setCategoryList(tempArray);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleDeleteSubCategory = (id) => {
    if (deleteId) {
      deleteSubCategory(deleteId)
        .then((response) => {
          if (response?.status) {
            toast.success(response?.message);
            setDeleteId(null);
            setDeleteDialog(false)
          } else {
            toast.error(response?.message)
          }
        }).catch((e) => {
          toast.error(e?.response?.data?.message);
        })
        .finally(() => {
          subCategoriesGetFunction();
        });
    }

  }
  /* CHOOSE IMAGES */
  const onImageChange = (imageList) => {
    setSubCategoryImages(imageList);
  };

  /* DISABLE SUB CATEGORY FUNCTION */
  const disableCategory = (object) => {
    const obj = {
      category: object?.category?._id,
      name: object?.name,
      isMenu: object?.isMenu,
      isFeatured: object?.isFeatured,
      imageUrl: object?.image,
      status: 0
    }
    if (object?.description) {
      obj.description = object?.description
    }
    if (object?.shortDescription) {
      obj.shortDescription = object?.shortDescription
    }
    editSubCategoryDetailsById(object?._id, obj)
      .then((res) => {
        if (res?.status) {
          toast.success(res?.message);
        } else {
          console.log(res?.message);
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        subCategoriesGetFunction();
      });
  };

  /* ENABLE SUB CATEGORY FUNCTION */
  const enableCategory = (object) => {
    const obj = {
      category: object?.category?._id,
      name: object?.name,
      isMenu: object?.isMenu,
      isFeatured: object?.isFeatured,
      imageUrl: object?.image,
      status: 1
    }
    if (object?.description) {
      obj.description = object?.description
    }
    if (object?.shortDescription) {
      obj.shortDescription = object?.shortDescription
    }
    editSubCategoryDetailsById(object?._id, obj)
      .then((res) => {
        if (res?.status) {
          toast.success(res?.message);
        } else {
          console.log(res?.message);
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        subCategoriesGetFunction();
      });
  };

  const handlePageClick = (event) => {
    const selectedPage = event.selected + 1;
    setLoading(true);
    setSubCategoryList([]);
    setPage(selectedPage);
  };

  /* USE-EFFECTS */
  useEffect(() => {
    subCategoriesGetFunction();
  }, [page, sizePerPage, selectedStatus, search])

  useEffect(() => {
    categoryGetFunction();
  }, [])


  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Sub categories | Fashion N Fun</title>
      </Helmet>

      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
              Sub Categories
            </h1>
            <h2 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight">
              Manage the sub-categories currently present on the website
            </h2>
          </div>

          <div className="block xl:flex lg:flex items-center gap-2">
            {/* SEARCHBAR */}
            <div className="w-full xl:w-searchBar lg:w-searchBar mt-2 xl:mt-0 lg:mt-0">
              <Input
                type="text"
                placeholder="Search your sub-categories"
                name="categories"
                id="categories"
                value={search}
                onChange={(event) => setSearch(event?.target?.value)}
              />
            </div>

            {/* STATUS DROPDOWN */}
            <div className="w-full xl:w-statusSelect lg:w-statusSelect mt-2 xl:mt-0 lg:mt-0">
              <Select
                options={status}
                classNamePrefix="dropdown"
                placeholder="Select status"
                name="status"
                id="status"
                value={selectedStatus}
                onChange={(e) => {
                  e?.value === "clear" ? setSelectedStatus("") : setSelectedStatus(e)
                }}
              />
            </div>

            {/* CATEGORY DROPDOWN */}
            <div className="w-full xl:w-categorySelect lg:w-categorySelect mt-2 xl:mt-0 lg:mt-0">
              <Select
                options={categoryList}
                classNamePrefix="dropdown"
                placeholder="Select category"
                name="category"
                id="category"
              />
            </div>

            {/* ADD CATEGORY */}
            <Button
              className="w-full xl:w-auto lg:w-auto mt-2 xl:mt-0 lg:mt-0 bg-logoYellow rounded-lg py-3 px-8 text-sm font-proxima-semibold shadow-none hover:shadow-none normal-case hover:bg-opacity-80 transition-all duration-300 ease-in-out"
              onClick={() => setAddDialog(!addDialog)}
            >
              Add sub-category
            </Button>
          </div>
        </div>

        {subCategoryList?.length === 0 && loading === true && (
          <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5 mt-3">
            <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight text-center">Please wait we are fetching your sub-categories...</p>
          </div>
        )}
        {subCategoryList?.length === 0 && loading === false && (
          <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5 mt-3">
            <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight text-center">We were not able to find any sub-categories.</p>
          </div>
        )}


        {/* CATEGORIES LIST */}
        <div className="mt-5 xl:mt-10 lg:mt-10 grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-y-5 xl:gap-5 lg:gap-5">
          {subCategoryList?.map((data) => (
            <div className="col-span-1" key={data.id}>
              <CategoryBox
                id={data._id}
                name={data.name}
                enabled={data.status === 1 ? true : false}
                details={data}
              />
            </div>
          ))}
        </div>
      </div>
      {subCategoryList?.length > 0 && <ReactPaginate
        className="lists-pagination"
        previousLabel={
          <Button className="list-pagination-btn">
            <GrPrevious color="#323232" size="20" />
          </Button>
        }
        nextLabel={
          <Button className="list-pagination-btn">
            <GrNext color="#323232" size="20" />
          </Button>
        }
        breakLabel={"..."}
        pageCount={totalPages}
        marginPagesDisplayed={3}
        pageRangeDisplayed={2}
        onPageChange={handlePageClick}
        activeClassName={"active"}
      />}

      {/* ADD SUB CATERGORY DIALOG */}
      <Dialog
        open={addDialog}
        handler={() => setAddDialog(!addDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody className="p-0">
          <form onSubmit={addSubCategoryFormik.handleSubmit}>
            <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
              <h5 className="text-lg font-proxima-bold text-black tracking-tight">
                Add Sub-Category
              </h5>
              <Button
                className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                onClick={() => setAddDialog(!addDialog)}
              >
                <IoClose className="w-4 h-4 text-greyText" />
              </Button>
            </div>
            <div className="p-5 border-b border-authBorder">

              {/* SELECT CATEGORY */}
              <div className="form-group">
                <label className="text-sm font-proxima-semibold text-black">
                  Category <span className="text-red-500">*</span>
                </label>
                <div className="mt-1">
                  <Select
                    options={categoryList}
                    classNamePrefix={addSubCategoryFormik.errors.category && addSubCategoryFormik.touched.category ? "dropdown_error" : "dropdown"}
                    onChange={(event) => addSubCategoryFormik.setFieldValue("category", event)}
                    value={addSubCategoryFormik.values.category}
                    placeholder="Select"
                    name="category"
                    id="category"
                  />
                </div>
              </div>

              {/* SUB-CATEGORY NAME */}
              <div className="form-group mt-2">
                <label className="text-sm font-proxima-semibold text-black">
                  Sub-Category Name <span className="text-red-500">*</span>
                </label>
                <div className="mt-1">
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    onChange={addSubCategoryFormik.handleChange}
                    value={addSubCategoryFormik.values.name}
                    error={addSubCategoryFormik.errors.name && addSubCategoryFormik.touched.name ? true : false}
                    placeholder="Write the sub-category name"
                  />
                </div>
              </div>

              <div className="mt-2 grid grid-cols-1 xl:grid-cols-6 gap-3">

                {/* SHOW IN MENU */}
                <div className="form-group col-span-3">
                  <label className="text-sm font-proxima-semibold text-black">
                    Show In Menu <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <Select
                      options={options}
                      classNamePrefix={addSubCategoryFormik.errors.isMenu && addSubCategoryFormik.touched.isMenu ? "dropdown_error" : "dropdown"}
                      onChange={(event) => addSubCategoryFormik.setFieldValue("isMenu", event)}
                      value={addSubCategoryFormik.values.isMenu}
                      placeholder="Select"
                      name="status"
                      id="status"
                    />
                  </div>
                </div>

                {/* SHOW AS FEATURED */}
                <div className="form-group col-span-3">
                  <label className="text-sm font-proxima-semibold text-black">
                    Show As Featured <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <Select
                      options={options}
                      classNamePrefix={addSubCategoryFormik.errors.isFeatured && addSubCategoryFormik.touched.isFeatured ? "dropdown_error" : "dropdown"}
                      onChange={(event) => addSubCategoryFormik.setFieldValue("isFeatured", event)}
                      value={addSubCategoryFormik.values.isFeatured}
                      placeholder="Select"
                      name="status"
                      id="status"
                    />
                  </div>
                </div>

              </div>

              {/* DESCRIPTION */}
              <div className="form-group mt-2">
                <label className="text-sm font-proxima-semibold text-black">
                  Description{" "}
                  <span className="text-red-500">*</span>
                </label>
                <div className="mt-1">
                  <Editor
                    toolbar={{ options: ["inline", "link"] }}
                    editorClassName="text-editor h-auto"
                    wrapperClassName={addSubCategoryFormik.errors.description && addSubCategoryFormik.touched.description ? "text-editor-wrapper-error" : "text-editor-wrapper"}
                    placeholder="Write the sub-category description here"
                    onEditorStateChange={(e) => {
                      addSubCategoryFormik.setFieldValue("description", e);
                    }}
                    editorState={addSubCategoryFormik.values.description}
                  />
                </div>
              </div>

              {/* SUB-CATEGORY IMAGES */}
              <div className="bg-white rounded-lg border border-authBorder mt-3">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-sm font-proxima-bold text-black">
                    Sub-Category Images
                  </h5>
                  <p className="text-xs font-proxima-regular text-greyText">
                    Attach the sub-category images for this product
                  </p>
                </div>
                <div className="p-5">
                  <ReactImageUploading
                    value={subCategoryImages}
                    onChange={onImageChange}
                    maxNumber={1}
                    dataURLKey="data_url"
                  >
                    {({ imageList, onImageUpload, onImageRemove }) => (
                      <div className="grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-6 gap-5">
                        <div className="col-span-3">
                          <div
                            className="w-full h-productImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder cursor-pointer hover:border-logoYellow transition-all duration-300 ease-in-out p-5"
                            onClick={onImageUpload}
                          >
                            <AiFillPlusCircle className="w-8 h-8 text-greyText mx-auto" />
                            <div className="mt-2">
                              <p className="text-xs font-proxima-semibold text-greyText text-center">
                                Click here to upload images
                              </p>
                              <p className="text-xs font-proxima-regular text-greyText text-center mt-1">
                                (JPG, JPEG, PNG)
                              </p>
                            </div>
                          </div>
                        </div>
                        {imageList.map((image, index) => (
                          <div className="col-span-3 relative" key={index}>
                            <img
                              src={image["data_url"]}
                              className="w-full h-productImage object-cover rounded-md"
                              alt="Product"
                            />
                            <div className="absolute top-2 right-2">
                              <Button
                                className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                                onClick={() => onImageRemove(index)}
                              >
                                <FiTrash className="w-3 h-3 text-black" />
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ReactImageUploading>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-end px-5 py-3">
              <Button disabled={addSubCategoryFormLoading} type="submit" className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-proxima-semibold text-white transition-all duration-300 ease-in-out normal-case">
                {addSubCategoryFormLoading ? "Please wait.." : "Save details"}
              </Button>
            </div>
          </form>
        </DialogBody>
      </Dialog>

      {/* EDIT SUB CATEGORY DIALOG */}
      <Dialog
        open={editDialog}
        handler={() => setEditDialog(!editDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody className="p-0">
          <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
            <h5 className="text-lg font-proxima-bold text-black tracking-tight">
              Edit Sub category
            </h5>
            <Button
              className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
              onClick={() => setEditDialog(!editDialog)}
            >
              <IoClose className="w-4 h-4 text-greyText" />
            </Button>
          </div>
          <div className="p-5 border-b border-authBorder">
            <div className="form-group">
              <label className="text-sm font-proxima-semibold text-black">
                Sub-category Name <span className="text-red-500">*</span>
              </label>
              <div className="mt-1">
                <Input
                  type="text"
                  name="categoryName"
                  id="categoryName"
                  placeholder="Write the sub-category name"
                />
              </div>
            </div>
            <div className="mt-5">
              <div className="form-group">
                <label className="text-sm font-proxima-semibold text-black">
                  Category <span className="text-red-500">*</span>
                </label>
                <div className="mt-1">
                  <Select
                    options={categoryOptions}
                    classNamePrefix="dropdown"
                    placeholder="Select category"
                    name="category"
                    id="category"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end px-5 py-3">
            <Button className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-proxima-semibold text-white transition-all duration-300 ease-in-out normal-case">
              Update details
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* DELETE SUB CATEGORY DIALOG */}
      <Dialog
        open={deleteDialog}
        handler={() => setDeleteDialog(!deleteDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody className="p-0">
          <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
            <h5 className="text-lg font-proxima-bold text-black tracking-tight">
              Delete Category
            </h5>
            <Button
              className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
              onClick={() => setDeleteDialog(!deleteDialog)}
            >
              <IoClose className="w-4 h-4 text-greyText" />
            </Button>
          </div>
          <div className="p-5 border-b border-authBorder">
            <p className="text-base font-proxima-regular text-black">
              Are you sure you want to delete this category?
            </p>
          </div>
          <div className="flex items-center justify-end px-5 py-3">
            <Button onClick={() => handleDeleteSubCategory()} className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-proxima-semibold text-white transition-all duration-300 ease-in-out normal-case">
              Delete details
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* VIEW SUB CATEGORY DIALOG */}
      <Dialog
        open={viewDialog}
        handler={() => setViewDialog(!viewDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody className="p-0">
          <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
            <h5 className="text-lg font-proxima-bold text-black tracking-tight">
              View Category
            </h5>
            <Button
              className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
              onClick={() => setViewDialog(!viewDialog)}
            >
              <IoClose className="w-4 h-4 text-greyText" />
            </Button>
          </div>
          <div className="p-5 pt-2">
            <div className="flex items-center justify-between">
              <h5 className="text-lg font-proxima-bold text-black ">
                {viewDetails && viewDetails?.name}
              </h5>
            </div>
            <div className="mt-1">
              <p className="text-sm font-proxima-regular text-greyText">
                {ReactHtmlParser(viewDetails && viewDetails?.description)}
              </p>
              <p className="text-xs font-proxima-regular text-greyText italic">
                Show In Menu : <span className="font-proxima-bold text-black">{viewDetails && viewDetails?.isMenu ? "Enabled" : "Disabled"}</span>
              </p>
              <p className="text-xs font-proxima-regular text-greyText italic">
                Show In Featured : <span className="font-proxima-bold text-black">{viewDetails && viewDetails?.isFeatured ? "Enabled" : "Disabled"}</span>
              </p>
              <div className="flex items-center gap-2 mt-2">
                {viewDetails && viewDetails?.status === 1 && (
                  <Button className="shadow-none hover:shadow-none px-4 py-2 rounded-md border border-green-500 bg-green-500 transition-all duration-300 ease-in-out text-white font-proxima-semibold text-xs normal-case leading-none font-medium">
                    Enabled
                  </Button>
                )}

                {viewDetails && viewDetails?.status === 0 && (
                  <Button className="shadow-none hover:shadow-none px-4 py-2 rounded-md border border-red-500 bg-red-500 transition-all duration-300 ease-in-out text-white font-proxima-semibold text-xs normal-case leading-none font-medium">
                    Disabled
                  </Button>
                )}
              </div>
              {viewDetails && viewDetails?.image && <div className="mt-3">
                <div className="grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-6 gap-5">
                  <div className="col-span-3 bg-white rounded-lg relative" >
                    <img className="rounded-lg" alt={viewDetails && viewDetails?.name} src={viewDetails && IMAGE_URL+viewDetails?.image} />
                  </div>
                </div>
              </div>}

            </div>
          </div>

        </DialogBody>
      </Dialog>

    </section>
  );
};

export default SubCategories;
