/* eslint-disable no-unused-vars */
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Editor } from "react-draft-wysiwyg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Select from "react-select";


/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* REDUX IMPORTS */
import { useSelector } from "react-redux";

/* FORM CONTROL */
import { useFormik } from "formik";
import * as yup from "yup";


/* REACT ICONS */

/* USER DEFINED COMPONENTS */
import InputField from "../../../components/input";

/* API IMPORT */
import { addProduct, getCategoryList, getColorList, getProductList, getSubCategoryList, getTagList } from "../../../service/api";

/* STATUS OPTIONS */
const statusOptions = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "In-Active" },
  { value: "comingSoon", label: "Coming Soon" },
];


/* Validation Schema */
// Validation Schema For PromoCode Add Form
const validationSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.mixed().required(),
  shortDescription: yup.string(),
  weight: yup.number().required(),
  category: yup.array().required(),
  subCategory: yup.array(),
  tag: yup.array(),
  metaTagsTitle: yup.string(),
  metaTagsKeyWords: yup.string(),
  metaTagsDescription: yup.string(),
  attributes: yup.object()
});

const AddProduct = () => {

  // GLOBAL VARIABLES
  const userDetails = useSelector((state) => state?.user);

  // STATE VARIABLES 
  const [loading, setLoading] = useState(false);
  const [colorsList, setColorsList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const [category, setCategory] = useState();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  // Formik Function For Add Product
  const addProductFormik = useFormik({
    initialValues: {
      name: "",
      description: "",
      shortDescription: "",
      weight: "",
      category: "",
      subCategory: "",
      tag: "",
      metaTagsTitle: "",
      metaTagsKeyWords: "",
      metaTagsDescription: "",
      attributes: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const obj = {}

      if (values?.name !== "") {
        obj.name = values?.name
      }
      if (values?.description !== "") {
        obj.description = draftToHtml(convertToRaw(values?.description?.getCurrentContent()))
      }
      if (values?.shortDescription !== "") {
        obj.shortDescription = values?.shortDescription
      }
      
      if (values?.weight !== "") {
        obj.weight = values?.weight
      }

      if (values?.metaTagsTitle !== "") {
        obj.metaTagsTitle = values?.metaTagsTitle
      }
      if (values?.metaTagsKeyWords !== "") {
        obj.metaTagsKeyWords = values?.metaTagsKeyWords
      }
      if (values?.metaTagsDescription !== "") {
        obj.metaTagsDescription = values?.metaTagsDescription
      }

      let tempCategoryArray = []
      values?.category?.forEach((el) => {
        tempCategoryArray.push(el?.value)
      })
      obj.category = tempCategoryArray;


      let tempSubCategoryArray = []
      values?.subCategory?.forEach((el) => {
        tempSubCategoryArray.push(el?.value)
      })
      obj.subCategory = tempSubCategoryArray;


      let tempTagArray = []
      values?.tag?.forEach((el) => {
        tempTagArray.push(el?.value)
      })
      obj.tag = tempTagArray;

      setLoading(true);
      addProduct(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            // addProductFormik.resetForm();
            setLoading(!loading);
          } else {
            console.log(res?.message);
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setLoading(false);
        });
    }
  })

  // Functions
  const productsGetFunction = () => {
    const obj = {
      page: 1,
      sizePerPage: 1,
      // pagination: false
    };
    getProductList(obj)
      .then((response) => {
        if (response?.status) {
          console.log("response", response)
          let tempArray = [];
          response?.data?.docs?.forEach((element) => {
            tempArray?.push({ value: element?._id, label: element?.name })
          })
          setProductsList(tempArray);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const categoriesGetFunction = () => {
    const obj = {
      page: 1,
      sizePerPage: 1,
      pagination: false
    };
    getCategoryList(obj)
      .then((response) => {
        if (response?.status) {
          console.log("response", response)
          let tempArray = [];
          response?.data?.forEach((element) => {
            tempArray?.push({ value: element?._id, label: element?.name })
          })
          setCategoryList(tempArray);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const subCategoriesGetFunction = () => {
    const obj = {
      page: 1,
      sizePerPage: 1,
      pagination: false
    };
    getSubCategoryList(obj)
      .then((response) => {
        if (response?.status) {
          console.log("response", response)
          let tempArray = [];
          response?.data?.forEach((element) => {
            tempArray?.push({ value: element?._id, label: element?.name })
          })
          setSubCategoryList(tempArray);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const tagsGetFunction = () => {
    const obj = {
      page: 1,
      sizePerPage: 1,
      pagination: false
    };
    getTagList(obj)
      .then((response) => {
        if (response?.status) {
          console.log("response", response)
          let tempArray = [];
          response?.data?.forEach((element) => {
            tempArray?.push({ value: element?._id, label: element?.name })
          })
          setTagList(tempArray);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const colorsGetFunction = () => {
    const obj = {
      page: 1,
      sizePerPage: 1,
      pagination: false
    };
    getColorList(obj)
      .then((response) => {
        if (response?.status) {
          console.log("response", response)
          let tempArray = [];
          response?.data?.forEach((element) => {
            tempArray?.push({ value: element?._id, label: element?.name + " (#" + element?.code + ")", code: element?.code })
          })
          setColorsList(tempArray);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  useEffect(() => {
    productsGetFunction();
    categoriesGetFunction();
    subCategoriesGetFunction();
    tagsGetFunction();
    colorsGetFunction();
  }, [])

  console.log("ERROR",addProductFormik.errors)
  console.log("VALUES",addProductFormik.values)
  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Add Product | Fashion N Fun</title>
      </Helmet>

      <div className="container-full-width">
        <form onSubmit={addProductFormik.handleSubmit}>
          {/* HEADING */}
          <div className="block xl:flex lg:flex items-center justify-between">
            <div>
              <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
                Add Product
              </h1>
              <h2 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight">
                Fill in the following details to add the product in the inventory
              </h2>
            </div>
            <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
              <Link to="/inventory">
                <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                  Back to Inventory
                </Button>
              </Link>
              <Button type="submit" disabled={loading} className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md border border-logoYellow text-sm font-proxima-semibold text-white normal-case transition-all duration-300 ease-in-out">
                {loading ? "Please wait..." : "Save details"}
              </Button>
            </div>
          </div>

          {/* FORM */}

          <div className="mt-5">
            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-y-5 xl:gap-5 lg:gap-5">
              {/* DETAILS */}
              <div className="col-span-2">
                <div className="bg-white rounded-lg border border-authBorder">
                  <div className="px-5 py-3 border-b border-authBorder">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Product Details
                    </h5>
                    <p className="text-sm font-proxima-regular text-greyText">
                      Fill in these product specific details
                    </p>
                  </div>
                  <div className="p-5">
                    <form>
                      <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">

                        {/* NAME */}
                        <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Product Name <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="text"
                                name="name"
                                id="name"
                                onChange={addProductFormik.handleChange}
                                value={addProductFormik.values.name}
                                error={addProductFormik.errors.name && addProductFormik.touched.name ? true : false}
                                placeholder="Write the product name"
                              />
                            </div>
                          </div>
                        </div>

                        {/* SKU */}
                        {/* <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Product SKU <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="text"
                                name="sku"
                                id="sku"
                                onChange={addProductFormik.handleChange}
                                value={addProductFormik.values.sku}
                                error={addProductFormik.errors.sku && addProductFormik.touched.sku ? true : false}
                                placeholder="Write the product sku"
                              />
                            </div>
                          </div>
                        </div> */}

                        {/* WEIGHT */}
                        <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Product Weight <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="number"
                                name="weight"
                                id="weight"
                                onChange={addProductFormik.handleChange}
                                value={addProductFormik.values.weight}
                                error={addProductFormik.errors.weight && addProductFormik.touched.weight ? true : false}
                                placeholder="Write the product weight"
                              />
                            </div>
                          </div>
                        </div>

                        {/* AMOUNT */}
                        {/* <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Product Price (₹){" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="number"
                                name="amount"
                                id="amount"
                                onChange={addProductFormik.handleChange}
                                value={addProductFormik.values.amount}
                                error={addProductFormik.errors.amount && addProductFormik.touched.amount ? true : false}
                                placeholder="Write the product price"
                              />
                            </div>
                          </div>
                        </div> */}

                        {/* QUANTITY */}
                        {/* <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Stock in inventory{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="number"
                                name="quantity"
                                id="quantity"
                                onChange={addProductFormik.handleChange}
                                value={addProductFormik.values.quantity}
                                error={addProductFormik.errors.quantity && addProductFormik.touched.quantity ? true : false}
                                placeholder="Write the stock in inventory"
                              />
                            </div>
                          </div>
                        </div> */}


                        {/* COLOR */}
                        {/* <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Product Color{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <Select
                                options={colorsList}
                                placeholder="Select Color"
                                name="color"
                                id="color"
                                classNamePrefix={addProductFormik.errors.color && addProductFormik.touched.color ? "dropdown_error" : "dropdown"}
                                onChange={(event) => addProductFormik.setFieldValue("color", event?.value)}
                                value={colorsList?.find((el) => el?.value === addProductFormik.values.color)}
                              />
                            </div>
                          </div>
                        </div> */}


                        {/* CATEGORY */}
                        <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Product Category{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <Select
                                options={categoryList}
                                placeholder="Select category"
                                name="productCategory"
                                id="productCategory"
                                isMulti
                                classNamePrefix={addProductFormik.errors.category && addProductFormik.touched.category ? "dropdown_error" : "dropdown"}
                                onChange={(event) => addProductFormik.setFieldValue("category", event)}
                                value={addProductFormik.values.category}
                              />
                            </div>
                          </div>
                        </div>

                        {/* SUB-CATEGORY */}
                        <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Product Sub-Category{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <Select
                                options={subCategoryList}
                                placeholder="Select sub-category"
                                name="productCategory"
                                id="productCategory"
                                isMulti
                                classNamePrefix={addProductFormik.errors.subCategory && addProductFormik.touched.subCategory ? "dropdown_error" : "dropdown"}
                                onChange={(event) => addProductFormik.setFieldValue("subCategory", event)}
                                value={addProductFormik.values.subCategory}
                              />
                            </div>
                          </div>
                        </div>

                        {/* TAGS */}
                        <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Product Tags{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <Select
                                options={tagList}
                                placeholder="Select tags"
                                name="tags"
                                id="tags"
                                isMulti
                                classNamePrefix={addProductFormik.errors.tag && addProductFormik.touched.tag ? "dropdown_error" : "dropdown"}
                                onChange={(event) => addProductFormik.setFieldValue("tag", event)}
                                value={addProductFormik.values.tag}
                              />
                            </div>
                          </div>
                        </div>


                        {/* NAME */}
                        <div className="col-span-2 ">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Short Description
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="text"
                                name="shortDescription"
                                id="shortDescription"
                                onChange={addProductFormik.handleChange}
                                value={addProductFormik.values.shortDescription}
                                error={addProductFormik.errors.shortDescription && addProductFormik.touched.shortDescription ? true : false}
                                placeholder="Write the product short description"
                              />
                            </div>
                          </div>
                        </div>

                        {/* STATUS */}
                        {/* <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Product Status{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <Select
                                options={statusOptions}
                                classNamePrefix={addProductFormik.errors.status && addProductFormik.touched.status ? "dropdown_error" : "dropdown"}
                                placeholder="Select Status"
                                name="status"
                                id="status"
                                onChange={(event) => addProductFormik.setFieldValue("status", event?.value)}
                                value={statusOptions?.find((el) => el?.value === addProductFormik.values.status)}
                              />
                            </div>
                          </div>
                        </div> */}

                        {/* DESCRIPTION */}
                        <div className="col-span-2">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black h-96">
                              Product Description{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <Editor
                                toolbar={{ options: ["inline", "link"] }}
                                editorClassName="text-editor h-auto"
                                wrapperClassName={addProductFormik.errors.description && addProductFormik.touched.description ? "text-editor-wrapper-error" : "text-editor-wrapper"}

                                onEditorStateChange={(e) => {
                                  addProductFormik.setFieldValue("description", e);
                                }}
                                editorState={addProductFormik.values.description}
                                placeholder="Write the product description here"
                              />
                            </div>
                          </div>
                        </div>


                        {/* <div className="col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Product Ingredients{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <Editor
                                toolbar={{ options: ["inline", "link"] }}
                                editorClassName="text-editor h-auto"
                                wrapperClassName={addProductFormik.errors.ingredients && addProductFormik.touched.ingredients ? "text-editor-wrapper-error" : "text-editor-wrapper"}

                                onEditorStateChange={(e) => {
                                  addProductFormik.setFieldValue("ingredients", e);
                                }}
                                editorState={addProductFormik.values.ingredients}
                                placeholder="Write the product ingredients here"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              How to use{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <Editor
                                toolbar={{ options: ["inline", "link"] }}
                                editorClassName="text-editor h-auto"
                                wrapperClassName={addProductFormik.errors.howToUse && addProductFormik.touched.howToUse ? "text-editor-wrapper-error" : "text-editor-wrapper"}

                                onEditorStateChange={(e) => {
                                  addProductFormik.setFieldValue("howToUse", e);
                                }}
                                editorState={addProductFormik.values.howToUse}
                                placeholder="Write the product How to Use here"
                              />
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* IMAGES AND DISCOUNTS */}
              <div className="col-span-1">
                {/* PRODUCT IMAGES */}
                {/* <div className="bg-white rounded-lg border border-authBorder">
                  <div className="px-5 py-3 border-b border-authBorder">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Product Images
                    </h5>
                    <p className="text-sm font-proxima-regular text-greyText">
                      Attach the product images for this product
                    </p>
                  </div>
                  <div className="p-5">
                    <ReactImageUploading
                      multiple
                      value={productImages}
                      onChange={onImageChange}
                      maxNumber={5}
                      dataURLKey="data_url"
                    >
                      {({ imageList, onImageUpload, onImageRemove }) => (
                        <div className="grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                          <div className="col-span-1">
                            <div
                              className="w-full h-productImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder cursor-pointer hover:border-logoYellow transition-all duration-300 ease-in-out p-5"
                              onClick={onImageUpload}
                            >
                              <AiFillPlusCircle className="w-8 h-8 text-greyText mx-auto" />
                              <div className="mt-2">
                                <p className="text-xs font-proxima-semibold text-greyText text-center">
                                  Click here to upload images
                                </p>
                                <p className="text-xs font-proxima-regular text-greyText text-center mt-1">
                                  (JPG, JPEG, PNG)
                                </p>
                              </div>
                            </div>
                          </div>
                          {imageList.map((image, index) => (
                            <div className="col-span-1 relative" key={index}>
                              <img
                                src={image["data_url"]}
                                className="w-full h-productImage object-cover rounded-md"
                                alt="Product"
                              />
                              <div className="absolute top-2 right-2">
                                <Button
                                  className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                                  onClick={() => onImageRemove(index)}
                                >
                                  <FiTrash className="w-3 h-3 text-black" />
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ReactImageUploading>
                  </div>
                </div> */}

                {/* SPECIAL DISCOUNTS */}
                {/* <div className="bg-white rounded-lg border border-authBorder">
                  <div className="px-5 py-3 border-b border-authBorder">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Special Discounts
                    </h5>
                    <p className="text-sm font-proxima-regular text-greyText">
                      Enter the special price as well as the start date and end
                      date
                    </p>
                  </div>
                  <div className="p-5">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black">
                        Special Price
                      </label>
                      <div className="mt-1">
                        <InputField
                          type="number"
                          name="specialAmount"
                          id="specialAmount"
                          placeholder="Write the special price"
                          onChange={addProductFormik.handleChange}
                          value={addProductFormik.values.specialAmount}
                          error={addProductFormik.errors.specialAmount && addProductFormik.touched.specialAmount ? true : false}
                        />
                      </div>
                    </div>
                    <div className="mt-5 grid grid-cols-2 gap-5">
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Start Date
                          </label>
                          <div className="mt-1">
                            <ReactDatePicker
                              selected={addProductFormik.values.specialAmountStartDate}
                              error={addProductFormik.errors.specialAmountStartDate && addProductFormik.touched.specialAmountStartDate ? true : false}
                              onChange={(date) => addProductFormik.setFieldValue("specialAmountStartDate", date)}
                              calendarClassName="calendar"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            End Date
                          </label>
                          <div className="mt-1">
                            <ReactDatePicker
                              selected={addProductFormik.values.specialAmountEndDate}
                              error={addProductFormik.errors.specialAmountEndDate && addProductFormik.touched.specialAmountEndDate ? true : false}
                              onChange={(date) => addProductFormik.setFieldValue("specialAmountEndDate", date)}
                              calendarClassName="calendar"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="mt-5 bg-white rounded-lg border border-authBorder">
                  <div className="px-5 py-3 border-b border-authBorder">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Meta Titles
                    </h5>
                    <p className="text-sm font-proxima-regular text-greyText">
                      Enter the meta titles, descriptions and keywords for this product
                    </p>
                  </div>
                  <div className="p-5">

                    <div className="grid grid-cols-2 gap-5">
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Title
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="metaTagsTitle"
                              id="metaTagsTitle"
                              onChange={addProductFormik.handleChange}
                              value={addProductFormik.values.metaTagsTitle}
                              error={addProductFormik.errors.metaTagsTitle && addProductFormik.touched.metaTagsTitle ? true : false}
                              placeholder="Write the Meta Title"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Keywords
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="metaTagsKeyWords"
                              id="metaTagsKeyWords"
                              onChange={addProductFormik.handleChange}
                              value={addProductFormik.values.metaTagsKeyWords}
                              error={addProductFormik.errors.metaTagsKeyWords && addProductFormik.touched.metaTagsKeyWords ? true : false}
                              placeholder="Write the Meta Keywords"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 form-group">
                      <label className="text-sm font-proxima-semibold text-black">
                        Description
                      </label>
                      <div className="mt-1">
                        <InputField
                          type="text"
                          name="metaTagsDescription"
                          id="metaTagsDescription"
                          onChange={addProductFormik.handleChange}
                          value={addProductFormik.values.metaTagsDescription}
                          error={addProductFormik.errors.metaTagsDescription && addProductFormik.touched.metaTagsDescription ? true : false}
                          placeholder="Write the Meta Description"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

      </div>
    </section>
  );
};

export default AddProduct;
