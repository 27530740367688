/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lone-blocks */
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import Select from "react-select";
/* DATEPICKER */
import { DateRangePicker } from "react-date-range";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Dialog,
  DialogBody,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";

/* ICONS */
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoClose, } from "react-icons/io5";


/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* REDUX IMPORTS */
import { useSelector } from "react-redux";

/* FORM CONTROL */
import { useFormik } from "formik";
import * as yup from "yup";

/* USER DEFINED COMPONENTS */
import Input from "../../../components/input";

/* API IMPORT */
import moment from "moment";
import { addPromoCode, getCategoryList, getProductList, getPromoCodeList, getSubCategoryList, getTagList, getUsersList } from "../../../service/api";

/* STATUS */
const status = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

/* PROMOCODE OPTIONS */
const promoOptions = [
  { label: "Flat", value: "flat" },
  { label: "Percentage", value: "percentage" },
  { label: "Free-Delivery", value: "free-delivery" }
];



/* Validation Schema */
// Validation Schema For PromoCode Add Form
const validationSchemaForPromoCodeAdd = yup.object().shape({
  code: yup.string().required("Code is required."),
  categoryId: yup.array(),
  subCategoryId: yup.array(),
  tagIdArr: yup.array(),
  userIdArr: yup.array(),
  productIdArr: yup.array(),
  productRequiredIdArr: yup.array(),
  type: yup.object().required("Type is required."),
  startDate: yup.date().required("Start Date is required"),
  endDate: yup.date().required("End Date is required"),
  // discountInAmount: yup.number().required('Discount amount is required when type is flat'),
  discountInAmount: yup.number(),
  // discountInRate: yup.number().required("Discount Rate is required when type is percentage"),
  discountInRate: yup.number(),
  allowPerUserUser: yup.number().required("Please mention User Allowance Type"),
  // maxAmountDiscount: yup.number().required("Maximum Amount in Discount is required when type is percentage"),
  maxAmountDiscount: yup.number(),
  minAmountToBuy: yup.number().required("Minimum Amount to Buy is required"),
  description: yup.mixed().required("Description is required"),
});

const OffersAndPromocodes = () => {

  // GLOBAL VARIABLES
  const userDetails = useSelector((state) => state?.user);


  // STATE VARIABLES 
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [promoCodeList, setPromoCodeList] = useState([]);
  const [totalDocs, setTotalDocs] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const [usersList, setUserList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const [search, setSearch] = useState("");

  const [selectedType, setSelectedType] = useState(null);

  const [addDialog, setAddDialog] = useState(false);
  const [addPromoCodeFormLoading, setAddPromoCodeFormLoading] = useState(false);

  const [editId, setEditId] = useState(null);
  const [editDialog, setEditDialog] = useState(false);

  const [deleteId, setDeleteId] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);


  const [datePickerDialog, setDatePickerDialog] = useState(false);
  const [dateRangeValue, setDateRangeValue] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });


  // Formik Function For Add PromoCode
  const addPromoCodeFormik = useFormik({
    initialValues: {
      code: "",
      categoryId: "",
      subCategoryId: "",
      tagIdArr: "",
      userIdArr: "",
      productIdArr: "",
      productRequiredIdArr: "",
      type: "",
      startDate: "",
      endDate: "",
      discountInAmount: "",
      discountInRate: "",
      allowPerUserUser: "",
      maxAmountDiscount: "",
      minAmountToBuy: "",
      description: "",
      addToOdoo: { label: "No", value: "No" }
    },
    validationSchema: validationSchemaForPromoCodeAdd,
    onSubmit: (values) => {
      const obj = {}

      if (values?.categoryId !== "") {
        obj.categoryId = values?.categoryId

        let subCategoryTempArray = []
        values?.categoryId?.forEach((ele) => {
          subCategoryTempArray.push(ele?.value)
        })
        obj.categoryId = subCategoryTempArray
      }

      if (values?.productIdArr !== "") {
        let subProductTempArray = []
        values?.productIdArr?.forEach((ele) => {
          subProductTempArray.push(ele?.value)
        })
        obj.productIdArr = subProductTempArray
      }

      if (values?.subCategoryId !== "") {
        let subCategoryTempArray = []
        values?.subCategoryId?.forEach((ele) => {
          subCategoryTempArray.push(ele?.value)
        })
        obj.subCategoryId = subCategoryTempArray
      }

      if (values?.userIdArr !== "") {
        let userIdTempArray = []
        values?.userIdArr?.forEach((ele) => {
          userIdTempArray.push(ele?.value)
        })
        obj.userIdArr = userIdTempArray
      }

      if (values?.tagIdArr !== "") {
        let tagTempArray = []
        values?.tagIdArr?.forEach((ele) => {
          tagTempArray.push(ele?.value)
        })
        obj.tagIdArr = tagTempArray
      }

      if (values?.productRequiredIdArr !== "") {
        let productRequiredTempArray = []
        values?.productRequiredIdArr?.forEach((ele) => {
          productRequiredTempArray.push(ele?.value)
        })
        obj.productRequiredIdArr = productRequiredTempArray
      }
      if (values?.code !== "") {
        obj.code = values?.code
      }

      if (values?.type !== "") {
        obj.type = values?.type?.value
      }
      if (values?.description !== "") {
        obj.description = draftToHtml(convertToRaw(values?.description?.getCurrentContent()))
      }
      if (values?.startDate !== "") {
        obj.startDate = new Date(values?.startDate)
      }
      if (values?.endDate !== "") {
        obj.endDate = new Date(values?.endDate)
      }
      if (values?.discountInAmount !== "") {
        obj.discountInAmount = values?.discountInAmount
      }
      if (values?.discountInRate !== "") {
        obj.discountInRate = values?.discountInRate
      }
      if (values?.allowPerUserUser !== "") {
        obj.allowPerUserUser = values?.allowPerUserUser
      }
      if (values?.maxAmountDiscount !== "") {
        obj.maxAmountDiscount = values?.maxAmountDiscount
      }
      if (values?.minAmountToBuy !== "") {
        obj.minAmountToBuy = values?.minAmountToBuy
      }

      obj.isAddedToOdd = values?.addToOdoo?.value === "Yes" ? true : false

      setAddPromoCodeFormLoading(true);
      addPromoCode(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            addPromoCodeFormik.resetForm();
            setAddDialog(!addDialog);
          } else {
            console.log(res?.message);
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setAddPromoCodeFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          promoCodeGetFunction();
          setAddPromoCodeFormLoading(false);
        });
    }
  })

  console.log("values", addPromoCodeFormik.values)

  // Functions
  const productsGetFunction = () => {
    const obj = {
      page,
      sizePerPage,
      // pagination: false
    };
    if (selectedType) {
      obj.selectedType = selectedType;
    }

    setLoading(true);
    getProductList(obj)
      .then((response) => {
        if (response?.status) {
          console.log("response", response)
          let tempArray = [];
          response?.data?.docs?.forEach((element) => {
            tempArray?.push({ value: element?._id, label: element?.name })
          })
          setProductsList(tempArray);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const usersGetFunction = () => {
    const obj = {
      page,
      sizePerPage,
      // pagination: false
    };
    if (selectedType) {
      obj.selectedType = selectedType;
    }

    setLoading(true);
    getUsersList(obj)
      .then((response) => {
        if (response?.status) {
          console.log("response", response)
          let tempArray = [];
          response?.data?.docs?.forEach((element) => {
            tempArray.push({ value: element?._id, label: element?.firstName + " " + element?.lastName + " (" + element?.email + ")" })
          })
          setUserList(tempArray);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const categoryGetFunction = () => {
    const obj = {
      page,
      sizePerPage,
      // pagination: false
    };
    if (selectedType) {
      obj.selectedType = selectedType;
    }

    setLoading(true);
    getCategoryList(obj)
      .then((response) => {
        if (response?.status) {
          console.log("response", response)
          let tempArray = [];
          response?.data?.docs?.forEach((element) => {
            tempArray.push({ value: element?._id, label: element?.name })
          })
          setCategoryList(tempArray);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const subCategoryGetFunction = () => {
    const obj = {
      page,
      sizePerPage,
      // pagination: false
    };
    if (selectedType) {
      obj.selectedType = selectedType;
    }

    setLoading(true);
    getSubCategoryList(obj)
      .then((response) => {
        if (response?.status) {
          console.log("response", response)
          let tempArray = [];
          response?.data?.docs?.forEach((element) => {
            tempArray.push({ value: element?._id, label: element?.name })
          })
          setSubCategoryList(tempArray);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const tagsGetFunction = () => {
    const obj = {
      page,
      sizePerPage,
      // pagination: false
    };
    if (selectedType) {
      obj.selectedType = selectedType;
    }

    setLoading(true);
    getTagList(obj)
      .then((response) => {
        if (response?.status) {
          console.log("response", response)
          let tempArray = [];
          response?.data?.docs?.forEach((element) => {
            tempArray.push({ value: element?._id, label: element?.name })
          })
          setTagList(tempArray);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const promoCodeGetFunction = () => {
    const obj = {
      page,
      sizePerPage,
    };
    if (selectedType) {
      obj.selectedType = selectedType;
    }

    setLoading(true);
    getPromoCodeList(obj)
      .then((response) => {
        if (response?.status) {
          setPromoCodeList(response?.data?.docs);
          setTotalDocs(response?.data?.totalDocs);
          setTotalPages(response?.data?.totalPages);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };


  /* PROMOCODE BOX */
  const PromocodeBox = (props) => {
    return (
      <div className="bg-white rounded-lg border border-authBorder">
        <div className="p-5">
          <div className="flex items-center justify-between">
            <h5 className="text-lg font-proxima-bold text-black uppercase leading-none">
              {props?.details?.code}
            </h5>
            <Menu placement="right">
              <MenuHandler>
                <Button className="p-[0.5vh] shadow-none hover:shadow-none bg-white rounded-full border border-authBorder hover:border-logoYellow transition-all duration-300 ease-in-out">
                  <HiOutlineDotsVertical className="w-4 h-4 text-black" />
                </Button>
              </MenuHandler>
              <MenuList className="p-2">
                <MenuItem
                  className="text-sm font-proxima-semibold text-black"
                  onClick={() => setEditDialog(!editDialog)}
                >
                  View Promo-Code
                </MenuItem>
                <MenuItem
                  className="text-sm font-proxima-semibold text-black"
                  onClick={() => setEditDialog(!editDialog)}
                >
                  Edit Promo-Code
                </MenuItem>
                <MenuItem
                  className="text-sm font-proxima-semibold text-black"
                  onClick={() => setDeleteDialog(!deleteDialog)}
                >
                  Delete Promo-Code
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
          <div className="mt-3">
            <div className="mt-2">
              <p className="text-sm font-proxima-regular text-greyText">
                Coupon Type:
                <span className="font-proxima-bold text-black pl-2">
                  {promoOptions?.find((el) => el?.value === props?.details?.type)?.label}
                </span>
              </p>
            </div>

            <div className="mt-1">
              <p className="text-sm font-proxima-regular text-greyText">
                {ReactHtmlParser(props?.details?.description)}
                {/* This promocode is for the customers who are shopping for first
                to the website */}
              </p>
            </div>

            <div className="mt-2">
              <p className="text-sm font-proxima-regular text-greyText">
                Start Date:
                <span className="font-proxima-bold text-black pl-2">
                  {moment(props?.details?.startDate).format("DD MMMM YYYY")}
                </span>
              </p>
              <p className="text-sm font-proxima-regular text-greyText">
                End Date:
                <span className="font-proxima-bold text-black pl-2">
                  {moment(props?.details?.endDate).format("DD MMMM YYYY")}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // UseEffect
  useEffect(() => {
    promoCodeGetFunction();
  }, [page, sizePerPage, selectedType]);

  useEffect(() => {
    productsGetFunction();
    usersGetFunction();
    categoryGetFunction();
    subCategoryGetFunction();
    tagsGetFunction();
  }, [])

  useEffect(() => {
    console.log("dateRangeValue", dateRangeValue)
    if (dateRangeValue) {
      addPromoCodeFormik.setFieldValue("startDate", dateRangeValue?.startDate)
      addPromoCodeFormik.setFieldValue("endDate", dateRangeValue?.endDate)
    }
  }, [dateRangeValue])

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Offers and Promocodes | Fashion N Fun</title>
      </Helmet>

      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
              Offers and Promocodes
            </h1>
            <h2 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight">
              Manage the offers and promocodes for your customers
            </h2>
          </div>

          <div className="block xl:flex lg:flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
            {/* SEARCHBAR */}
            <div className="w-full xl:w-searchBar lg:w-searchBar">
              <Input
                type="text"
                placeholder="Search your promocode"
                name="promocode"
                id="promocode"
              />
            </div>

            {/* STATUS DROPDOWN */}
            <div className="w-full xl:w-statusSelect lg:w-statusSelec mt-2 xl:mt-0 lg:mt-0">
              <Select
                options={status}
                classNamePrefix="dropdown"
                placeholder="Select status"
                name="status"
                id="status"
              />
            </div>

            {/* ADD TAG */}
            <Button
              className="w-full xl:w-auto lg:w-auto mt-2 xl:mt-0 lg:mt-0 bg-logoYellow rounded-lg py-3 px-8 text-sm font-proxima-semibold shadow-none hover:shadow-none normal-case hover:bg-opacity-80 transition-all duration-300 ease-in-out"
              onClick={() => setAddDialog(!addDialog)}
            >
              Add promocode
            </Button>
          </div>
        </div>

        {promoCodeList?.length === 0 && loading === true && (
          <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5 mt-3">
            <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight text-center">Please wait we are fetching your promo-codes...</p>
          </div>
        )}
        {promoCodeList?.length === 0 && loading === false && (
          <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5 mt-3">
            <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight text-center">We were not able to find any promo-codes.</p>
          </div>
        )}

        {/* PROMOCODES */}
        <div className="grid grid-cols-1 xl:grid-cols-5 lg:grid-cols-5 gap-3 xl:gap-3 lg:gap-3 mt-5">
          {promoCodeList?.map((element, i) => {
            return <PromocodeBox details={element} name={element?.code} />
          })}

        </div>
      </div>

      {/* ADD PROMOCODE */}
      <Dialog
        open={addDialog}
        handler={() => setAddDialog(!addDialog)}
        size="lg"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody className="p-0">
          <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
            <h5 className="text-lg font-proxima-bold text-black tracking-tight">
              Add Promocode
            </h5>
            <Button
              className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
              onClick={() => setAddDialog(!addDialog)}
            >
              <IoClose className="w-4 h-4 text-greyText" />
            </Button>
          </div>
          <form onSubmit={addPromoCodeFormik.handleSubmit}>

            <div className={`p-5 border-b border-authBorder`}>
              <div className="grid grid-cols-12 gap-5">

                {/* CODE */}
                <div className="col-span-6 lg:col-span-6">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Promocode Name <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Input
                        type="text"
                        name="code"
                        id="code"
                        onChange={addPromoCodeFormik.handleChange}
                        value={addPromoCodeFormik.values.code}
                        error={addPromoCodeFormik.errors.code && addPromoCodeFormik.touched.code ? true : false}
                        placeholder="Write the promocode name"
                      />
                    </div>
                  </div>
                </div>

                {/* TYPE */}
                <div className="col-span-6 lg:col-span-6">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Promocode Type <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Select
                        options={promoOptions}
                        className="error"
                        classNamePrefix={addPromoCodeFormik.errors.type && addPromoCodeFormik.touched.type ? "dropdown_error" : "dropdown"}
                        placeholder="Select promocode type"
                        name="promoCode"
                        id="promoCode"
                        onChange={(event) => addPromoCodeFormik.setFieldValue("type", event)}
                        value={addPromoCodeFormik.values.type}
                      />
                    </div>
                  </div>
                </div>

                {/* DATE RANGE */}
                <div className="col-span-6 lg:col-span-4">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Date Range <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Input
                        type="button"
                        name="startDate"
                        id="startDate"
                        onClick={() => { setDatePickerDialog(!datePickerDialog) }}
                        value={addPromoCodeFormik.values.startDate ? `${moment(new Date(addPromoCodeFormik.values.startDate)).format("DD/MM/YYYY")} - ${moment(new Date(addPromoCodeFormik.values.endDate)).format("DD/MM/YYYY")}` : "Select Date Range"}
                        error={addPromoCodeFormik.errors.startDate && addPromoCodeFormik.touched.startDate ? true : false}
                        placeholder="Write the promocode name"
                      />
                    </div>
                  </div>
                </div>

                {/* ALLOWED PER USER */}
                <div className="col-span-6 lg:col-span-4">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Allowed Per User <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Input
                        type="number"
                        name="allowPerUserUser"
                        id="allowPerUserUser"
                        onChange={addPromoCodeFormik.handleChange}
                        value={addPromoCodeFormik.values.allowPerUserUser}
                        error={addPromoCodeFormik.errors.allowPerUserUser && addPromoCodeFormik.touched.allowPerUserUser ? true : false}
                        placeholder="1"
                      />
                    </div>
                  </div>
                </div>

                {/* MINIMUM AMOUNT TO BUY */}
                <div className="col-span-6 lg:col-span-4">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Minimum Amount To Purchase <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Input
                        type="number"
                        name="minAmountToBuy"
                        id="minAmountToBuy"
                        onChange={addPromoCodeFormik.handleChange}
                        value={addPromoCodeFormik.values.minAmountToBuy}
                        error={addPromoCodeFormik.errors.minAmountToBuy && addPromoCodeFormik.touched.minAmountToBuy ? true : false}
                        placeholder="10"
                      />
                    </div>
                  </div>
                </div>

                {/* DISCOUNT IN AMOUNT */}
                {addPromoCodeFormik?.values?.type?.value === 'flat' && <div className="col-span-6 lg:col-span-4">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Discount in Amount <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Input
                        type="number"
                        name="discountInAmount"
                        id="discountInAmount"
                        onChange={addPromoCodeFormik.handleChange}
                        min={0}
                        value={addPromoCodeFormik.values.discountInAmount}
                        error={addPromoCodeFormik.errors.discountInAmount && addPromoCodeFormik.touched.discountInAmount ? true : false}
                        placeholder="10"
                      />
                    </div>
                  </div>
                </div>}

                {/* DISCOUNT IN RATE */}
                {addPromoCodeFormik?.values?.type?.value === 'percentage' && <div className="col-span-6 lg:col-span-4">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Discount in Rate <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Input
                        type="number"
                        name="discountInRate"
                        id="discountInRate"
                        onChange={addPromoCodeFormik.handleChange}
                        min={0}
                        value={addPromoCodeFormik.values.discountInRate}
                        error={addPromoCodeFormik.errors.discountInRate && addPromoCodeFormik.touched.discountInRate ? true : false}
                        placeholder="10"
                      />
                    </div>
                  </div>
                </div>}

                {/* MAXIMUM DISCOUNT IN AMOUNT */}
                {addPromoCodeFormik?.values?.type?.value === 'percentage' && <div className="col-span-6 lg:col-span-4">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Maximum Discount Amount <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Input
                        type="number"
                        name="maxAmountDiscount"
                        id="maxAmountDiscount"
                        onChange={addPromoCodeFormik.handleChange}
                        min={0}
                        value={addPromoCodeFormik.values.maxAmountDiscount}
                        error={addPromoCodeFormik.errors.maxAmountDiscount && addPromoCodeFormik.touched.maxAmountDiscount ? true : false}
                        placeholder="10"
                      />
                    </div>
                  </div>
                </div>}

                {/* DESCRIPTION */}
                <div className="col-span-12 h-auto">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Promocode Description{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Editor
                        toolbar={{ options: ["inline", "link"] }}
                        editorClassName="text-editor h-auto"
                        wrapperClassName={addPromoCodeFormik.errors.description && addPromoCodeFormik.touched.description ? "text-editor-wrapper-error" : "text-editor-wrapper"}
                        placeholder="Write the promocode description here"
                        onEditorStateChange={(e) => {
                          addPromoCodeFormik.setFieldValue("description", e);
                        }}
                        editorState={addPromoCodeFormik.values.description}
                      />
                    </div>
                  </div>
                </div>

                {/* USER  */}
                <div className="col-span-6 lg:col-span-6">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Select User <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Select
                        options={usersList}
                        classNamePrefix={addPromoCodeFormik.errors.userIdArr && addPromoCodeFormik.touched.userIdArr ? "dropdown_error" : "dropdown"}
                        placeholder="Select User"
                        name="userIdArr"
                        id="userIdArr"
                        isMulti={true}
                        onChange={(event) => addPromoCodeFormik.setFieldValue("userIdArr", event)}
                        value={addPromoCodeFormik.values.userIdArr}
                      />
                    </div>
                  </div>
                </div>

                {/* PRODUCT  */}
                <div className="col-span-6 lg:col-span-6">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Select Product <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Select
                        options={productsList}
                        className="error"
                        classNamePrefix={addPromoCodeFormik.errors.productIdArr && addPromoCodeFormik.touched.productIdArr ? "dropdown_error" : "dropdown"}
                        placeholder="Select Product"
                        name="productIdArr"
                        id="productIdArr"
                        isMulti={true}
                        onChange={(event) => addPromoCodeFormik.setFieldValue("productIdArr", event)}
                        value={addPromoCodeFormik.values.productIdArr}
                      />
                    </div>
                  </div>
                </div>

                {/* CATEGORY  */}
                <div className="col-span-6 lg:col-span-4">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Select Category <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Select
                        options={categoryList}
                        className="error"
                        classNamePrefix={addPromoCodeFormik.errors.categoryId && addPromoCodeFormik.touched.categoryId ? "dropdown_error" : "dropdown"}
                        placeholder="Select Category"
                        name="categoryId"
                        id="categoryId"
                        isMulti={true}
                        onChange={(event) => addPromoCodeFormik.setFieldValue("categoryId", event)}
                        value={addPromoCodeFormik.values.categoryId}
                      />
                    </div>
                  </div>
                </div>

                {/* SUB-CATEGORY  */}
                <div className="col-span-6 lg:col-span-4">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Select Sub-Category <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Select
                        options={subCategoryList}
                        className="error"
                        classNamePrefix={addPromoCodeFormik.errors.subCategoryId && addPromoCodeFormik.touched.subCategoryId ? "dropdown_error" : "dropdown"}
                        placeholder="Select Category"
                        name="subCategoryId"
                        id="subCategoryId"
                        isMulti={true}
                        onChange={(event) => addPromoCodeFormik.setFieldValue("subCategoryId", event)}
                        value={addPromoCodeFormik.values.subCategoryId}
                      />
                    </div>
                  </div>
                </div>

                {/* TAGS  */}
                <div className="col-span-6 lg:col-span-4">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Select Tags <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Select
                        options={tagList}
                        className="error"
                        classNamePrefix={addPromoCodeFormik.errors.tagIdArr && addPromoCodeFormik.touched.tagIdArr ? "dropdown_error" : "dropdown"}
                        placeholder="Select Tag"
                        name="tagIdArr"
                        id="tagIdArr"
                        isMulti={true}
                        onChange={(event) => addPromoCodeFormik.setFieldValue("tagIdArr", event)}
                        value={addPromoCodeFormik.values.tagIdArr}
                      />
                    </div>
                  </div>
                </div>

                {/* CROSS-PRODUCT  */}
                <div className="col-span-6 lg:col-span-6">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Select Cross-Product <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Select
                        options={productsList}
                        className="error"
                        classNamePrefix={addPromoCodeFormik.errors.productRequiredIdArr && addPromoCodeFormik.touched.productRequiredIdArr ? "dropdown_error" : "dropdown"}
                        placeholder="Select Cross-Product"
                        name="productRequiredIdArr"
                        id="productRequiredIdArr"
                        isMulti={true}
                        onChange={(event) => addPromoCodeFormik.setFieldValue("productRequiredIdArr", event)}
                        value={addPromoCodeFormik.values.productRequiredIdArr}
                      />
                    </div>
                  </div>
                </div>

                {/* ODOO  */}
                <div className="col-span-6 lg:col-span-3">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Add In Odoo <span className="text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <Select
                        options={[{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }]}
                        className="error"
                        classNamePrefix={addPromoCodeFormik.errors.addToOdoo && addPromoCodeFormik.touched.addToOdoo ? "dropdown_error" : "dropdown"}
                        placeholder="Select Cross-Product"
                        name="addToOdoo"
                        id="addToOdoo"
                        onChange={(event) => addPromoCodeFormik.setFieldValue("addToOdoo", event)}
                        value={addPromoCodeFormik.values.addToOdoo}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* SUBMIT BUTTON */}
            <div className="flex items-center justify-end px-5 py-3">
              <Button type="submit" disabled={addPromoCodeFormLoading} className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-proxima-semibold text-white transition-all duration-300 ease-in-out normal-case">
                {addPromoCodeFormLoading ? "Please wait" : "Save details"}
              </Button>
            </div>
          </form>

          {/* DATE PICKER DIALOG */}
          <Dialog
            open={datePickerDialog}
            handler={() => setDatePickerDialog(!datePickerDialog)}
            size="md"
            animate={{
              mount: { scale: 1, y: 0 },
              unmount: { scale: 0.9, y: -100 },
            }}
          >
            <DialogBody className="p-0">
              <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
                <h5 className="text-lg font-proxima-bold text-black tracking-tight">
                  &nbsp;
                </h5>
                <Button
                  className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                  onClick={() => setDatePickerDialog(!datePickerDialog)}
                >
                  <IoClose className="w-4 h-4 text-greyText" />
                </Button>
              </div>
              <div className=" right-0 transition-all duration-300  ease-linear z-50">
                <DateRangePicker
                  ranges={[dateRangeValue]}
                  dragSelectionEnabled
                  showPreview
                  onChange={(ranges) => {
                    setDateRangeValue(ranges?.selection)
                  }}
                  staticRanges={[

                  ]}
                  className="shadow-xl px-5 overflow-auto font-proxima-regular rounded-xl border border-authBorder cursor-pointer"
                />
              </div>
            </DialogBody>
          </Dialog>

        </DialogBody>
      </Dialog>

      {/* EDIT PROMOCODE */}
      <Dialog
        open={editDialog}
        handler={() => setEditDialog(!editDialog)}
        size="sm"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody className="p-0">
          <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
            <h5 className="text-lg font-proxima-bold text-black tracking-tight">
              Edit Promocode
            </h5>
            <Button
              className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
              onClick={() => setEditDialog(!editDialog)}
            >
              <IoClose className="w-4 h-4 text-greyText" />
            </Button>
          </div>
          <div className="p-5 border-b border-authBorder">
            <div className="grid grid-cols-2 gap-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label className="text-sm font-proxima-semibold text-black">
                    Promocode Name <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <Input
                      type="text"
                      name="promocodeName"
                      id="promocodeName"
                      placeholder="Write the promocode name"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label className="text-sm font-proxima-semibold text-black">
                    Promocode Type <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <Select
                      options={promoOptions}
                      classNamePrefix="dropdown"
                      placeholder="Select promocode type"
                      name="promoCode"
                      id="promoCode"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-2">
                <div className="form-group">
                  <label className="text-sm font-proxima-semibold text-black">
                    Promocode Description{" "}
                    <span className="text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <Editor
                      toolbar={{ options: ["inline", "link"] }}
                      editorClassName="text-editor"
                      wrapperClassName="text-editor-wrapper"
                      placeholder="Write the promocode description here"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end px-5 py-3">
            <Button className="text-sm shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-proxima-semibold text-white transition-all duration-300 ease-in-out normal-case">
              Save details
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* DELETE TAG DIALOG */}
      <Dialog
        open={deleteDialog}
        handler={() => setDeleteDialog(!deleteDialog)}
        size="xs"
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
      >
        <DialogBody className="p-0">
          <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
            <h5 className="text-lg font-proxima-bold text-black tracking-tight">
              Delete Promocode
            </h5>
            <Button
              className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
              onClick={() => setDeleteDialog(!deleteDialog)}
            >
              <IoClose className="w-4 h-4 text-greyText" />
            </Button>
          </div>
          <div className="p-5 border-b border-authBorder">
            <p className="text-base font-proxima-regular text-black">
              Are you sure you want to delete this promocode?
            </p>
          </div>
          <div className="flex items-center justify-end px-5 py-3">
            <Button className="shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md font-proxima-semibold text-white transition-all text-sm duration-300 ease-in-out normal-case">
              Delete details
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};

export default OffersAndPromocodes;
