import axios from 'axios';
import { SERVER_URL } from '../config/index';
import store from '../redux/store';

const request = axios.create({
  baseURL: SERVER_URL,
  timeout: 1000000,
});
let requests = [];
request.interceptors.request.use(
  (config) => {
    // do something before request is sent
    if (store.getState().user?.token) {
      config.headers.Authorization = `${store.getState().user?.token}`;
    }

    requests.push(config);
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  },
);

/* Auth API */
// Login
export const adminLogin = (data) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(SERVER_URL + "auth/login", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Register
export const adminRegister = (data) => {
  return new Promise(async (resolve, reject) => {
    await axios
      .post(SERVER_URL + "auth/register", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Products API */
// Add Product
export const addProduct = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("product/", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Edit Product Details By Id
export const editProductDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`product/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Edit Product Details By Id
export const editProductStatusById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`product/status/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Product List
export const getProductList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/product`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Product Details By Id
export const getProductDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`product/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete Product Details By Id
export const deleteProduct = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`product/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Add Color For Product By Id
export const addProductColorByProductId = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`product/quantity/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Edit Color For Product By Id
export const editProductColorByProductId = (id, body, quantityId) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`product/quantity/` + id + "/" + quantityId, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Upload Image For Product By Id
export const addProductImageByProductId = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`product/image/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update Uploaded Image By Id For Product By Id
export const updateProductImageByProductImageId = (id, productImageId, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`product/image/` + id + `/` + productImageId, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Remove Image For Product By Id
export const deleteProductImageByProductImageId = (id, productImageId, productQuantityId) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`product/image/` + id + `/` + productImageId + "/" + productQuantityId)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Users API */
// Get Product List
export const getUsersList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/user`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Product Details By Id
export const getUsersDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`user/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Update Product Details By Id
export const editUsersDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`user/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Remove Product Details By Id
export const deleteUsers = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`user/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};



/* Categories API */
// Add Category
export const addCategory = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("category/", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Edit Category Details By Id
export const editCategoryDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`category/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Category List
export const getCategoryList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/category`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Category Details By Id
export const getCategoryDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`category/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete Category Details By Id
export const deleteCategory = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`category/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Sub Categories API */
// Add SubCategory
export const addSubCategory = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("sub-category/", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Edit SubCategory Details By Id
export const editSubCategoryDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`sub-category/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get SubCategory List
export const getSubCategoryList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/sub-category`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get SubCategory Details By Id
export const getSubCategoryDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`sub-category/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete SubCategory Details By Id
export const deleteSubCategory = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`sub-category/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Tags API */
// Add Tag
export const addTagDetails = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("tag/", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Edit Tag Details By Id
export const editTagDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`tag/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Edit Product Array For Tag  By Id
export const editTagProductArrayById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`tag/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Tag List
export const getTagList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/tag`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Tag Details By Id
export const getTagDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`tag/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete Tag Details By Id
export const deleteTag = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`tag/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};


/* Colors API */
// Add Color
export const addColor = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("color/", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Edit Color Details By Id
export const editColorDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`color/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Color List
export const getColorList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/color`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Color Details By Id
export const getColorDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`color/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete Color Details By Id
export const deleteColor = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`color/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};


/* Sizes API */
// Add Size
export const addSizeDetails = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("size/", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Edit Size Details By Id
export const editSizeDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`size/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Edit Product Array For Size  By Id
export const editSizeProductArrayById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .patch(`size/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Size List
export const getSizeList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/size`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get Size Details By Id
export const getSizeDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`size/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete Size Details By Id
export const deleteSize = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`size/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};



/* Contact Us */
// Get Contact Us List
export const getContactUsList = (data, token) => {
  return new Promise(async (resolve, reject) => {
    await request.get('contact-us', { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete PromoCode Details By Id
export const deleteContactUsDetailsById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request.delete(`/contact-us/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* Analytics */
// Get Dashboard Analytics Data
export const getAnalyticsList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/order/sales/report`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/* PromoCode API */
// Add Promo Code
export const addPromoCode = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("promo-code/", data)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Edit PromoCode Details By Id
export const editPromoCodeDetailsById = (id, body) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put(`promo-code/` + id, body)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Get PromoCode List
export const getPromoCodeList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`/promo-code`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
// Delete PromoCode Details By Id
export const deletePromoCode = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete(`promo-code/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Get Orders
export const getOrdersList = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`order`, { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Get Order Details By Id
export const getOrderDetailsByIdApi = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`order/` + id)
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
