const inventory = [
  {
    id: 1,
    image:
      "https://food-farmacy.web.app/static/media/01.77836b00b8e4d6428956.jpg",
    name: "Blue Diamonds Almond Lightly Salted",
    sku: "BDALS",
    visibility: "Disabled",
    quantity: "100",
    category: "Breakfast, Cereals and Premixes",
  },
];

export default inventory;
