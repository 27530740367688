/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Editor } from "react-draft-wysiwyg";
import { Helmet } from "react-helmet";
import ReactImageUploading from "react-images-uploading";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";


/* MATERIAL TAILWIND COMPONENTS */
import { Button, Dialog, DialogBody } from "@material-tailwind/react";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* REDUX IMPORTS */
import { useSelector } from "react-redux";

/* FORM CONTROL */
import { useFormik } from "formik";
import * as yup from "yup";


/* REACT ICONS */
import { AiFillPlusCircle } from "react-icons/ai";
import { FiTrash } from "react-icons/fi";

/* USER DEFINED COMPONENTS */
import InputField from "../../../components/input";

/* API IMPORT */
import { addProductColorByProductId, addProductImageByProductId, deleteProductImageByProductImageId, editProductColorByProductId, editProductDetailsById, getCategoryList, getColorList, getProductDetailsById, getProductList, getSizeList, getSubCategoryList, getTagList } from "../../../service/api";
import { IoClose } from 'react-icons/io5';
import { IMAGE_URL } from '../../../config';

/* STATUS OPTIONS */
const statusOptions = [
  { value: "active", label: "Active" },
  { value: "inactive", label: "In-Active" },
  { value: "comingSoon", label: "Coming Soon" },
];


/* IS FEATURED OPTIONS */
const isFeaturedOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];


/* Validation Schema */
// Validation Schema For PromoCode Add Form
const validationSchemaForColorAdd = yup.object().shape({
  color: yup.string().required(),
  availableSize: yup.array(),
  isFeatured: yup.boolean().required()
});

// Validation Schema For PromoCode Add Form
const validationSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.mixed().required(),
  shortDescription: yup.string(),
  weight: yup.number().required(),
  category: yup.array().required(),
  subCategory: yup.array(),
  tag: yup.array(),
  metaTagsTitle: yup.string(),
  metaTagsKeyWords: yup.string(),
  metaTagsDescription: yup.string(),
  attributes: yup.object()
});

const EditProduct = () => {

  // GLOBAL VARIABLES
  const { id } = useParams();
  const userDetails = useSelector((state) => state?.user);

  // STATE VARIABLES 
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [colorsList, setColorsList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [sizeList, setSizeList] = useState([]);

  const [productDetails, setProductDetails] = useState(null);

  const [colorAddDialog, setColorAddDialog] = useState(false);
  const [availableSizeArray, setAvailableSizeArray] = useState([]);

  const [productImages, setProductImages] = useState([]);

  const [imageAddDialog, setImageAddDialog] = useState(false);
  const [selectedQuantityId, setSelectedQuantityId] = useState(null);
  const [uploading, setUploading] = useState(false);

  const [productFrontImages, setProductFrontImages] = useState([]);
  const [productBackImages, setProductBackImages] = useState([]);
  const [productOthersImages, setProductOthersImages] = useState([]);

  /* CHOOSE IMAGES */
  const onFrontImageChange = (imageList) => {
    setProductFrontImages(imageList);
  };
  const onBackImageChange = (imageList) => {
    setProductBackImages(imageList);
  };
  const onOtherImageChange = (imageList) => {
    setProductOthersImages(imageList);
  };


  // Formik Function For Add Product
  const addProductFormik = useFormik({
    initialValues: {
      name: "",
      description: "",
      shortDescription: "",
      weight: "",
      category: "",
      subCategory: "",
      tag: "",
      metaTagsTitle: "",
      metaTagsKeyWords: "",
      metaTagsDescription: "",
      attributes: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const obj = {}

      if (values?.name !== "") {
        obj.name = values?.name
      }
      if (values?.description !== "") {
        obj.description = draftToHtml(convertToRaw(values?.description?.getCurrentContent()))
      }
      if (values?.shortDescription !== "") {
        obj.shortDescription = values?.shortDescription
      }

      if (values?.weight !== "") {
        obj.weight = values?.weight
      }

      if (values?.metaTagsTitle !== "") {
        obj.metaTagsTitle = values?.metaTagsTitle
      }
      if (values?.metaTagsKeyWords !== "") {
        obj.metaTagsKeyWords = values?.metaTagsKeyWords
      }
      if (values?.metaTagsDescription !== "") {
        obj.metaTagsDescription = values?.metaTagsDescription
      }

      let tempCategoryArray = []
      values?.category?.forEach((el) => {
        tempCategoryArray.push(el?.value)
      })
      obj.category = tempCategoryArray;


      let tempSubCategoryArray = []
      values?.subCategory?.forEach((el) => {
        tempSubCategoryArray.push(el?.value)
      })
      obj.subCategory = tempSubCategoryArray;


      let tempTagArray = []
      values?.tag?.forEach((el) => {
        tempTagArray.push(el?.value)
      })
      obj.tag = tempTagArray;

      setLoading(true);
      editProductDetailsById(obj)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            // addProductFormik.resetForm();
            setLoading(!loading);
          } else {
            console.log(res?.message);
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setLoading(false);
        });
    }
  })

  /* ADD COLOR FORMIK HANDLER */
  const addColorFormikFunction = useFormik({
    initialValues: {
      color: "",
      availableSize: [],
      size: [],
      isFeatured: ""
    },
    validationSchema: validationSchemaForColorAdd,
    onSubmit: (values) => {
      const obj = {}

      if (values?.color !== "") {
        obj.color = values?.color
      }

      obj.isFeatured = values?.isFeatured

      let tempArray = []
      availableSizeArray?.forEach((el) => {
        let objTemp = {
          size: el?.size,
          amount: el?.amount,
          quantity: el?.quantity,
          sku: el?.sku,
          status: el?.status?.value
        }
        // if(el?.tag){
        //   objTemp.tag = el?.tag
        // }
        tempArray.push(objTemp)
      })
      obj.availableSize = tempArray;

      console.log("OBJ", obj)
      setLoading(true);
      addProductColorByProductId(id, obj)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            // editFormikFunction.resetForm();
            setLoading(!loading);
          } else {
            console.log(res?.message);
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setLoading(false);
        });
    }
  })

  /* ADD COLOR FORMIK HANDLER */
  const editColorFormikFunction = useFormik({
    initialValues: {
      color: "",
      availableSize: [],
      size: [],
      isFeatured: ""
    },
    validationSchema: validationSchemaForColorAdd,
    onSubmit: (values) => {
      const obj = {}

      if (values?.color !== "") {
        obj.color = values?.color
      }

      obj.isFeatured = values?.isFeatured

      let tempArray = []
      availableSizeArray?.forEach((el) => {
        let objTemp = {
          size: el?.size,
          amount: el?.amount,
          quantity: el?.quantity,
          sku: el?.sku,
          status: el?.status?.value
        }
        // if(el?.tag){
        //   objTemp.tag = el?.tag
        // }
        tempArray.push(objTemp)
      })
      obj.availableSize = tempArray;

      console.log("OBJ", obj)
      setLoading(true);
      editProductColorByProductId(id, obj, selectedQuantityId)
        .then((res) => {
          if (res?.status) {
            toast.success(res?.message);
            // editFormikFunction.resetForm();
            setLoading(!loading);
          } else {
            console.log(res?.message);
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          setLoading(false);
        });
    }
  })

  // Functions
  const productsGetFunction = () => {
    const obj = {
      page: 1,
      sizePerPage: 1,
      // pagination: false
    };
    getProductList(obj)
      .then((response) => {
        if (response?.status) {
          console.log("response", response)
          let tempArray = [];
          response?.data?.docs?.forEach((element) => {
            tempArray?.push({ value: element?._id, label: element?.name })
          })
          setProductsList(tempArray);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const categoriesGetFunction = () => {
    const obj = {
      page: 1,
      sizePerPage: 1,
      pagination: false
    };
    getCategoryList(obj)
      .then((response) => {
        if (response?.status) {
          console.log("response", response)
          let tempArray = [];
          response?.data?.forEach((element) => {
            tempArray?.push({ value: element?._id, label: element?.name })
          })
          setCategoryList(tempArray);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const subCategoriesGetFunction = () => {
    const obj = {
      page: 1,
      sizePerPage: 1,
      pagination: false
    };
    getSubCategoryList(obj)
      .then((response) => {
        if (response?.status) {
          console.log("response", response)
          let tempArray = [];
          response?.data?.forEach((element) => {
            tempArray?.push({ value: element?._id, label: element?.name })
          })
          setSubCategoryList(tempArray);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const tagsGetFunction = () => {
    const obj = {
      page: 1,
      sizePerPage: 1,
      pagination: false
    };
    getTagList(obj)
      .then((response) => {
        if (response?.status) {
          console.log("response", response)
          let tempArray = [];
          response?.data?.forEach((element) => {
            tempArray?.push({ value: element?._id, label: element?.name })
          })
          setTagList(tempArray);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const colorsGetFunction = () => {
    const obj = {
      page: 1,
      sizePerPage: 1,
      pagination: false
    };
    getColorList(obj)
      .then((response) => {
        if (response?.status) {
          console.log("response", response)
          let tempArray = [];
          response?.data?.forEach((element) => {
            tempArray?.push({ value: element?._id, label: element?.name + " (#" + element?.code + ")", code: element?.code })
          })
          setColorsList(tempArray);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  };
  const sizesGetFunction = () => {
    const obj = {
      page: 1,
      sizePerPage: 1,
      pagination: false
    };
    getSizeList(obj)
      .then((response) => {
        if (response?.status) {
          console.log("response", response)
          let tempArray = [];
          response?.data?.forEach((element) => {
            tempArray?.push({ value: element?._id, label: element?.name })
          })
          setSizeList(tempArray);
        }
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const handleGetProductDetails = () => {
    getProductDetailsById(id)
      .then((res) => {
        toast.success(res?.message);
        setProductDetails(res?.data);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemoveImage = (imageId) => {
    console.log("imageId", imageId)
    deleteProductImageByProductImageId(id, imageId, selectedQuantityId).then((res) => {
      toast.success(res?.message);
    })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        handleGetProductDetails();
      });
  }

  const handleAddProductImage = (imageUploadType) => {
    setUploading(true)
    const formData = new FormData();

    formData.append("productQuantityId", selectedQuantityId)
    formData.append("imageFor", imageUploadType)

    if (productFrontImages?.[0]?.file && imageUploadType === "front") {
      formData.append("image", productFrontImages?.[0]?.file);
    }
    if (productBackImages?.[0]?.file && imageUploadType === "back") {
      formData.append("image", productBackImages?.[0]?.file);
    }

    if (productOthersImages?.[0]?.file && imageUploadType === "others") {
      formData.append("image", productOthersImages?.[0]?.file);
    }
    addProductImageByProductId(id, formData)
      .then((res) => {
        toast.success(res?.message);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setUploading(false);
        handleGetProductDetails();
        if (imageUploadType === 'front') {
          setProductFrontImages([])
        } else if (imageUploadType === 'back') {
          setProductBackImages([])
        } else if (imageUploadType === 'others') {
          setProductOthersImages([])
        }
      });
  };

  const handleChangeColorSizeAndQuantity = (variable, sizeId, value) => {
    console.log("availableSizeArray", availableSizeArray)

    const current = availableSizeArray?.find((el) => el?.size === sizeId);

    const finalArray = []
    finalArray.push(current)

    availableSizeArray?.filter((el) => el?.size !== sizeId)?.forEach((el) => {
      finalArray.push(el)
    })

    current[variable] = value
    setAvailableSizeArray(finalArray.sort(function (a, b) { return a.index - b.index }))
  }


  useEffect(() => {
    productsGetFunction();
    categoriesGetFunction();
    subCategoriesGetFunction();
    tagsGetFunction();
    colorsGetFunction();
    sizesGetFunction();
  }, [])

  useEffect(() => {
    if (id) {
      handleGetProductDetails();
    }
  }, [id]);

  useEffect(() => {
    // if (productDetails && categoryList && colorsList) {

    //   editFormikFunction.setFieldValue("sku", productDetails?.sku);
    //   editFormikFunction.setFieldValue("name", productDetails?.name);

    //   // editFormikFunction.setFieldValue(
    //   //   "description",
    //   //   EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(productDetails?.description)?.contentBlocks, htmlToDraft(productDetails?.description)?.entityMap))
    //   // );
    //   editFormikFunction.setFieldValue(
    //     "shortDescription",
    //     productDetails?.shortDescription
    //   );

    //   // editFormikFunction.setFieldValue(
    //   //   "howToUse",
    //   //   EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(<p style="text-align:start;"></p>)?.contentBlocks, htmlToDraft(<p style="text-align:start;"></p>)?.entityMap))
    //   // );
    //   editFormikFunction.setFieldValue(
    //     "ingredients",
    //     EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(productDetails?.ingredients)?.contentBlocks, htmlToDraft(productDetails?.ingredients)?.entityMap))
    //   );
    //   editFormikFunction.setFieldValue("amount", productDetails?.amount);
    //   editFormikFunction.setFieldValue(
    //     "color",
    //     productDetails?.color?._id
    //   );

    //   editFormikFunction.setFieldValue(
    //     "specialAmount",
    //     productDetails?.specialAmount?.amount
    //   );
    //   editFormikFunction.setFieldValue(
    //     "specialAmountStartDate",
    //     productDetails?.specialAmount?.startDate
    //   );
    //   editFormikFunction.setFieldValue(
    //     "specialAmountEndDate",
    //     productDetails?.specialAmount?.endDate
    //   );

    //   editFormikFunction.setFieldValue("weight", productDetails?.weight);
    //   editFormikFunction.setFieldValue("quantity", productDetails?.quantity);
    //   let tempArray = []
    //   productDetails?.category?.docs?.forEach((el) => {
    //     tempArray.push(categoryList?.find((element) => element?.value === el?._id))
    //   })
    //   editFormikFunction.setFieldValue("category", tempArray);


    //   editFormikFunction.setFieldValue(
    //     "metaTagsTitle",
    //     productDetails?.metaTags?.title
    //   );
    //   editFormikFunction.setFieldValue(
    //     "metaTagsKeyWords",
    //     productDetails?.metaTags?.keyWords
    //   );
    //   editFormikFunction.setFieldValue(
    //     "metaTagsDescription",
    //     productDetails?.metaTags?.description
    //   );

    // }
  }, [productDetails, categoryList, colorsList]);

  useEffect(() => {
    if (addColorFormikFunction.values.size?.length > 0) {
      let tempArray = []
      addColorFormikFunction.values.size?.forEach((el, index) => {
        if (el?.value !== availableSizeArray?.find((ele) => ele?.size === el?.value)?.size) {
          tempArray.push({
            index: index,
            size: el?.value,
            sizeLabel: el?.label,
            amount: "",
            quantity: 0,
            sku: "",
            status: ""
          })
        } else {
          tempArray.push({
            index: index,
            size: el?.value,
            sizeLabel: el?.label,
            amount: el?.value === availableSizeArray?.find((ele) => ele?.size === el?.value)?.amount,
            quantity: el?.value === availableSizeArray?.find((ele) => ele?.size === el?.value)?.quantity,
            sku: el?.value === availableSizeArray?.find((ele) => ele?.size === el?.value)?.sku,
            status: el?.value === availableSizeArray?.find((ele) => ele?.size === el?.value)?.status
          })
        }
      })
      setAvailableSizeArray(tempArray.sort(function (a, b) { return a.index - b.index }))
    } else {
      setAvailableSizeArray([])
    }
  }, [addColorFormikFunction.values.size])

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Edit Product | Fashion N Fun</title>
      </Helmet>

      <div className="container-full-width">

        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
              Edit Product ({productDetails?.name})
            </h1>
            <h2 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight">
              Fill in the following details to add the product in the inventory
            </h2>
          </div>
          <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
            <Link to="/inventory">
              <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                Back to Inventory
              </Button>
            </Link>
            <Button onClick={() => { setColorAddDialog(!colorAddDialog) }} type="button" disabled={loading} className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md border border-logoYellow text-sm font-proxima-semibold text-white normal-case transition-all duration-300 ease-in-out">
              Add Color
            </Button>
          </div>
        </div>

        {/* FORM */}

        <div className="mt-5">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-y-5 xl:gap-5 lg:gap-5">
            {/* DETAILS */}
            <div className="col-span-2">
              <div className="bg-white rounded-lg border border-authBorder">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-lg font-proxima-bold text-black">
                    Product Details
                  </h5>
                  <p className="text-sm font-proxima-regular text-greyText">
                    Fill in these product specific details
                  </p>
                </div>
                <div className="p-5">
                  <form>
                    <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">

                      {/* NAME */}
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Product Name <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="name"
                              id="name"
                              onChange={addProductFormik.handleChange}
                              value={addProductFormik.values.name}
                              error={addProductFormik.errors.name && addProductFormik.touched.name ? true : false}
                              placeholder="Write the product name"
                            />
                          </div>
                        </div>
                      </div>

                      {/* SKU */}
                      {/* <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Product SKU <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="text"
                                name="sku"
                                id="sku"
                                onChange={addProductFormik.handleChange}
                                value={addProductFormik.values.sku}
                                error={addProductFormik.errors.sku && addProductFormik.touched.sku ? true : false}
                                placeholder="Write the product sku"
                              />
                            </div>
                          </div>
                        </div> */}

                      {/* WEIGHT */}
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Product Weight <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="number"
                              name="weight"
                              id="weight"
                              onChange={addProductFormik.handleChange}
                              value={addProductFormik.values.weight}
                              error={addProductFormik.errors.weight && addProductFormik.touched.weight ? true : false}
                              placeholder="Write the product weight"
                            />
                          </div>
                        </div>
                      </div>

                      {/* AMOUNT */}
                      {/* <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Product Price (₹){" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="number"
                                name="amount"
                                id="amount"
                                onChange={addProductFormik.handleChange}
                                value={addProductFormik.values.amount}
                                error={addProductFormik.errors.amount && addProductFormik.touched.amount ? true : false}
                                placeholder="Write the product price"
                              />
                            </div>
                          </div>
                        </div> */}

                      {/* QUANTITY */}
                      {/* <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Stock in inventory{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <InputField
                                type="number"
                                name="quantity"
                                id="quantity"
                                onChange={addProductFormik.handleChange}
                                value={addProductFormik.values.quantity}
                                error={addProductFormik.errors.quantity && addProductFormik.touched.quantity ? true : false}
                                placeholder="Write the stock in inventory"
                              />
                            </div>
                          </div>
                        </div> */}


                      {/* COLOR */}
                      {/* <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Product Color{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <Select
                                options={colorsList}
                                placeholder="Select Color"
                                name="color"
                                id="color"
                                classNamePrefix={addProductFormik.errors.color && addProductFormik.touched.color ? "dropdown_error" : "dropdown"}
                                onChange={(event) => addProductFormik.setFieldValue("color", event?.value)}
                                value={colorsList?.find((el) => el?.value === addProductFormik.values.color)}
                              />
                            </div>
                          </div>
                        </div> */}


                      {/* CATEGORY */}
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Product Category{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <Select
                              options={categoryList}
                              placeholder="Select category"
                              name="productCategory"
                              id="productCategory"
                              isMulti
                              classNamePrefix={addProductFormik.errors.category && addProductFormik.touched.category ? "dropdown_error" : "dropdown"}
                              onChange={(event) => addProductFormik.setFieldValue("category", event)}
                              value={addProductFormik.values.category}
                            />
                          </div>
                        </div>
                      </div>

                      {/* SUB-CATEGORY */}
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Product Sub-Category{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <Select
                              options={subCategoryList}
                              placeholder="Select sub-category"
                              name="productCategory"
                              id="productCategory"
                              isMulti
                              classNamePrefix={addProductFormik.errors.subCategory && addProductFormik.touched.subCategory ? "dropdown_error" : "dropdown"}
                              onChange={(event) => addProductFormik.setFieldValue("subCategory", event)}
                              value={addProductFormik.values.subCategory}
                            />
                          </div>
                        </div>
                      </div>

                      {/* TAGS */}
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Product Tags{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <Select
                              options={tagList}
                              placeholder="Select tags"
                              name="tags"
                              id="tags"
                              isMulti
                              classNamePrefix={addProductFormik.errors.tag && addProductFormik.touched.tag ? "dropdown_error" : "dropdown"}
                              onChange={(event) => addProductFormik.setFieldValue("tag", event)}
                              value={addProductFormik.values.tag}
                            />
                          </div>
                        </div>
                      </div>


                      {/* NAME */}
                      <div className="col-span-2 ">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Short Description
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="shortDescription"
                              id="shortDescription"
                              onChange={addProductFormik.handleChange}
                              value={addProductFormik.values.shortDescription}
                              error={addProductFormik.errors.shortDescription && addProductFormik.touched.shortDescription ? true : false}
                              placeholder="Write the product short description"
                            />
                          </div>
                        </div>
                      </div>

                      {/* STATUS */}
                      {/* <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Product Status{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <Select
                                options={statusOptions}
                                classNamePrefix={addProductFormik.errors.status && addProductFormik.touched.status ? "dropdown_error" : "dropdown"}
                                placeholder="Select Status"
                                name="status"
                                id="status"
                                onChange={(event) => addProductFormik.setFieldValue("status", event?.value)}
                                value={statusOptions?.find((el) => el?.value === addProductFormik.values.status)}
                              />
                            </div>
                          </div>
                        </div> */}

                      {/* DESCRIPTION */}
                      <div className="col-span-2">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black h-96">
                            Product Description{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <Editor
                              toolbar={{ options: ["inline", "link"] }}
                              editorClassName="text-editor h-auto"
                              wrapperClassName={addProductFormik.errors.description && addProductFormik.touched.description ? "text-editor-wrapper-error" : "text-editor-wrapper"}

                              onEditorStateChange={(e) => {
                                addProductFormik.setFieldValue("description", e);
                              }}
                              editorState={addProductFormik.values.description}
                              placeholder="Write the product description here"
                            />
                          </div>
                        </div>
                      </div>


                      {/* <div className="col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              Product Ingredients{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <Editor
                                toolbar={{ options: ["inline", "link"] }}
                                editorClassName="text-editor h-auto"
                                wrapperClassName={addProductFormik.errors.ingredients && addProductFormik.touched.ingredients ? "text-editor-wrapper-error" : "text-editor-wrapper"}

                                onEditorStateChange={(e) => {
                                  addProductFormik.setFieldValue("ingredients", e);
                                }}
                                editorState={addProductFormik.values.ingredients}
                                placeholder="Write the product ingredients here"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-1">
                          <div className="form-group">
                            <label className="text-sm font-proxima-semibold text-black">
                              How to use{" "}
                              <span className="text-red-500">*</span>
                            </label>
                            <div className="mt-1">
                              <Editor
                                toolbar={{ options: ["inline", "link"] }}
                                editorClassName="text-editor h-auto"
                                wrapperClassName={addProductFormik.errors.howToUse && addProductFormik.touched.howToUse ? "text-editor-wrapper-error" : "text-editor-wrapper"}

                                onEditorStateChange={(e) => {
                                  addProductFormik.setFieldValue("howToUse", e);
                                }}
                                editorState={addProductFormik.values.howToUse}
                                placeholder="Write the product How to Use here"
                              />
                            </div>
                          </div>
                        </div> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* IMAGES AND DISCOUNTS */}
            <div className="col-span-1">
              {/* PRODUCT IMAGES */}
              {/* <div className="bg-white rounded-lg border border-authBorder">
                  <div className="px-5 py-3 border-b border-authBorder">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Product Images
                    </h5>
                    <p className="text-sm font-proxima-regular text-greyText">
                      Attach the product images for this product
                    </p>
                  </div>
                  <div className="p-5">
                    <ReactImageUploading
                      multiple
                      value={productImages}
                      onChange={onImageChange}
                      maxNumber={5}
                      dataURLKey="data_url"
                    >
                      {({ imageList, onImageUpload, onImageRemove }) => (
                        <div className="grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                          <div className="col-span-1">
                            <div
                              className="w-full h-productImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder cursor-pointer hover:border-logoYellow transition-all duration-300 ease-in-out p-5"
                              onClick={onImageUpload}
                            >
                              <AiFillPlusCircle className="w-8 h-8 text-greyText mx-auto" />
                              <div className="mt-2">
                                <p className="text-xs font-proxima-semibold text-greyText text-center">
                                  Click here to upload images
                                </p>
                                <p className="text-xs font-proxima-regular text-greyText text-center mt-1">
                                  (JPG, JPEG, PNG)
                                </p>
                              </div>
                            </div>
                          </div>
                          {imageList.map((image, index) => (
                            <div className="col-span-1 relative" key={index}>
                              <img
                                src={image["data_url"]}
                                className="w-full h-productImage object-cover rounded-md"
                                alt="Product"
                              />
                              <div className="absolute top-2 right-2">
                                <Button
                                  className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                                  onClick={() => onImageRemove(index)}
                                >
                                  <FiTrash className="w-3 h-3 text-black" />
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ReactImageUploading>
                  </div>
                </div> */}

              {/* SPECIAL DISCOUNTS */}
              {/* <div className="bg-white rounded-lg border border-authBorder">
                  <div className="px-5 py-3 border-b border-authBorder">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Special Discounts
                    </h5>
                    <p className="text-sm font-proxima-regular text-greyText">
                      Enter the special price as well as the start date and end
                      date
                    </p>
                  </div>
                  <div className="p-5">
                    <div className="form-group">
                      <label className="text-sm font-proxima-semibold text-black">
                        Special Price
                      </label>
                      <div className="mt-1">
                        <InputField
                          type="number"
                          name="specialAmount"
                          id="specialAmount"
                          placeholder="Write the special price"
                          onChange={addProductFormik.handleChange}
                          value={addProductFormik.values.specialAmount}
                          error={addProductFormik.errors.specialAmount && addProductFormik.touched.specialAmount ? true : false}
                        />
                      </div>
                    </div>
                    <div className="mt-5 grid grid-cols-2 gap-5">
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Start Date
                          </label>
                          <div className="mt-1">
                            <ReactDatePicker
                              selected={addProductFormik.values.specialAmountStartDate}
                              error={addProductFormik.errors.specialAmountStartDate && addProductFormik.touched.specialAmountStartDate ? true : false}
                              onChange={(date) => addProductFormik.setFieldValue("specialAmountStartDate", date)}
                              calendarClassName="calendar"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            End Date
                          </label>
                          <div className="mt-1">
                            <ReactDatePicker
                              selected={addProductFormik.values.specialAmountEndDate}
                              error={addProductFormik.errors.specialAmountEndDate && addProductFormik.touched.specialAmountEndDate ? true : false}
                              onChange={(date) => addProductFormik.setFieldValue("specialAmountEndDate", date)}
                              calendarClassName="calendar"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

              <div className=" bg-white rounded-lg border border-authBorder">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-lg font-proxima-bold text-black">
                    Meta Titles
                  </h5>
                  <p className="text-sm font-proxima-regular text-greyText">
                    Enter the meta titles, descriptions and keywords for this product
                  </p>
                </div>
                <div className="p-5">

                  <div className="grid grid-cols-2 gap-5">
                    <div className="col-span-1">
                      <div className="form-group">
                        <label className="text-sm font-proxima-semibold text-black">
                          Title
                        </label>
                        <div className="mt-1">
                          <InputField
                            type="text"
                            name="metaTagsTitle"
                            id="metaTagsTitle"
                            onChange={addProductFormik.handleChange}
                            value={addProductFormik.values.metaTagsTitle}
                            error={addProductFormik.errors.metaTagsTitle && addProductFormik.touched.metaTagsTitle ? true : false}
                            placeholder="Write the Meta Title"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="form-group">
                        <label className="text-sm font-proxima-semibold text-black">
                          Keywords
                        </label>
                        <div className="mt-1">
                          <InputField
                            type="text"
                            name="metaTagsKeyWords"
                            id="metaTagsKeyWords"
                            onChange={addProductFormik.handleChange}
                            value={addProductFormik.values.metaTagsKeyWords}
                            error={addProductFormik.errors.metaTagsKeyWords && addProductFormik.touched.metaTagsKeyWords ? true : false}
                            placeholder="Write the Meta Keywords"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Description
                    </label>
                    <div className="mt-1">
                      <InputField
                        type="text"
                        name="metaTagsDescription"
                        id="metaTagsDescription"
                        onChange={addProductFormik.handleChange}
                        value={addProductFormik.values.metaTagsDescription}
                        error={addProductFormik.errors.metaTagsDescription && addProductFormik.touched.metaTagsDescription ? true : false}
                        placeholder="Write the Meta Description"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>


            {/* IMAGES AND COLOR */}
            {productDetails?.quantity?.map((element, index) => {
              element.availableSizeArray = []
              element.availableSize?.forEach((el) => {
                element.availableSizeArray.push(sizeList?.find((ele) => ele?.value === el?.size?._id))
              })
              return <div className='col-span-3'>
                <div className="bg-white rounded-lg border border-authBorder">

                  <div className="block px-5 py-3 border-b border-authBorder xl:flex lg:flex items-center justify-between">
                    <div>
                      <h5 className="text-lg font-proxima-bold text-black">
                        Quantity and Size Details for {colorsList?.find((el) => el?.value === element?.color?._id)?.label}
                      </h5>
                      <p className="text-sm font-proxima-regular text-greyText">
                        Fill in these product specific details
                      </p>
                    </div>
                    <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
                      <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                        Edit
                      </Button>
                      <Button type="button" onClick={() => {
                        setSelectedQuantityId(element?._id)
                        setImageAddDialog(!imageAddDialog)
                      }} disabled={loading} className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md border border-logoYellow text-sm font-proxima-semibold text-white normal-case transition-all duration-300 ease-in-out">
                        Add/Update Image
                      </Button>
                    </div>
                  </div>



                  <div className="p-5">

                    <div className="bg-white rounded-lg border border-authBorder">
                      <div className="p-5">
                        <div className="form-group">
                          <div className="grid grid-cols-6 gap-3">
                            {/* COLOR */}
                            <div className="col-span-6 xl:col-span-1 lg:col-span-1">
                              <div className="form-group">
                                <label className="text-sm font-proxima-semibold text-black">
                                  Product Color{" "}
                                  <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-1">
                                  <Select
                                    options={colorsList}
                                    placeholder="Select Color"
                                    name="color"
                                    id="color"
                                    isDisabled
                                    classNamePrefix={addColorFormikFunction.errors.color && addColorFormikFunction.touched.color ? "dropdown_error" : "dropdown"}
                                    value={colorsList?.find((el) => el?.value === element?.color?._id)}
                                  />
                                </div>
                              </div>
                            </div>


                            {/* STATUS */}
                            <div className="col-span-6 xl:col-span-1 lg:col-span-1">
                              <div className="form-group">
                                <label className="text-sm font-proxima-semibold text-black">
                                  Product Featured{" "}
                                  <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-1">
                                  <Select
                                    options={isFeaturedOptions}
                                    classNamePrefix={addColorFormikFunction.errors.isFeatured && addColorFormikFunction.touched.isFeatured ? "dropdown_error" : "dropdown"}
                                    placeholder="Select Is Featured"
                                    name="isFeatured"
                                    id="isFeatured"
                                    isDisabled
                                    value={isFeaturedOptions?.find((el) => el?.value === element.isFeatured)}
                                  />
                                </div>
                              </div>
                            </div>

                            {/* SIZE */}
                            <div className="col-span-6 xl:col-span-4 lg:col-span-4">
                              <div className="form-group">
                                <label className="text-sm font-proxima-semibold text-black">
                                  Product Available Sizes{" "}
                                  <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-1">
                                  <Select
                                    options={sizeList}
                                    placeholder="Select Size"
                                    name="size"
                                    id="size"
                                    isMulti
                                    isDisabled
                                    className='h-full'
                                    classNamePrefix={addColorFormikFunction.errors.size && addColorFormikFunction.touched.size ? "dropdown_error" : "dropdown"}
                                    value={element.availableSizeArray}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {availableSizeArray?.length > 0 && availableSizeArray?.map((element, index) => {

                      return <div key={index} className="bg-white rounded-lg border mt-3 border-authBorder">
                        <div className="px-5 py-3 border-b border-authBorder">
                          <h5 className="text-lg font-proxima-bold text-black capitalize">
                            {element?.sizeLabel} Size Quantity and Price
                          </h5>
                          <p className="text-sm font-proxima-regular text-greyText">
                            Attach the product images for this product
                          </p>
                        </div>
                        <div className="p-5">

                          <div className="form-group">
                            <div className="grid grid-cols-6 gap-3">
                              {/* SIZE */}
                              <div className="col-span-6 xl:col-span-1 lg:col-span-1">
                                <div className="form-group">
                                  <label className="text-sm font-proxima-semibold text-black">
                                    Size{" "}
                                    <span className="text-red-500">*</span>
                                  </label>
                                  <div className="mt-1">
                                    <InputField
                                      type="text"
                                      name="sku"
                                      id="sku"
                                      disabled={true}
                                      value={element?.sizeLabel}
                                      placeholder="Size"
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* SKU */}
                              <div className="col-span-6 xl:col-span-2 lg:col-span-2">
                                <div className="form-group">
                                  <label className="text-sm font-proxima-semibold text-black">
                                    SKU{" "}
                                    <span className="text-red-500">*</span>
                                  </label>
                                  <div className="mt-1">
                                    <InputField
                                      type="text"
                                      name="sku"
                                      id="sku"
                                      onChange={(event) => {
                                        handleChangeColorSizeAndQuantity("sku", element?.size, event?.target?.value)
                                      }}
                                      value={element?.sku}
                                      placeholder="SKU"
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* AMOUNT */}
                              <div className="col-span-6 xl:col-span-1 lg:col-span-1">
                                <div className="form-group">
                                  <label className="text-sm font-proxima-semibold text-black">
                                    Amount{" "}
                                    <span className="text-red-500">*</span>
                                  </label>
                                  <div className="mt-1">
                                    <InputField
                                      type="number"
                                      name="amount"
                                      id="amount"
                                      onChange={(event) => {
                                        handleChangeColorSizeAndQuantity("amount", element?.size, event?.target?.value)
                                      }}
                                      value={element?.amount}
                                      placeholder="Amount"
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* QUANTITY */}
                              <div className="col-span-6 xl:col-span-1 lg:col-span-1">
                                <div className="form-group">
                                  <label className="text-sm font-proxima-semibold text-black">
                                    Quantity{" "}
                                    <span className="text-red-500">*</span>
                                  </label>
                                  <div className="mt-1">
                                    <InputField
                                      type="number"
                                      name="quantity"
                                      id="quantity"
                                      onChange={(event) => {
                                        handleChangeColorSizeAndQuantity("quantity", element?.size, event?.target?.value)
                                      }}
                                      value={element?.quantity}
                                      placeholder="Quantity"
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* STATUS */}
                              <div className="col-span-6 xl:col-span-1 lg:col-span-1">
                                <label className="text-sm font-proxima-semibold text-black">
                                  Product Status{" "}
                                  <span className="text-red-500">*</span>
                                </label>
                                <div className="mt-1">
                                  <Select
                                    options={statusOptions}
                                    classNamePrefix={addColorFormikFunction.errors.status && addColorFormikFunction.touched.status ? "dropdown_error" : "dropdown"}
                                    placeholder="Select Status"
                                    name="status"
                                    id="status"
                                    onChange={(event) => {
                                      handleChangeColorSizeAndQuantity("status", element?.size, event)
                                    }}
                                    value={element?.status}
                                  />
                                </div>
                              </div>

                              {/* SPECIAL DISCOUNTS */}

                              {/* SPECIAL AMOUNT */}
                              <div className="col-span-6 xl:col-span-2 lg:col-span-2">
                                <div className="form-group">
                                  <label className="text-sm font-proxima-semibold text-black">
                                    Special Price
                                  </label>
                                  <div className="mt-1">
                                    <InputField
                                      type="number"
                                      name="specialAmount"
                                      id="specialAmount"
                                      placeholder="Write the special price"
                                    // onChange={editFormikFunction.handleChange}
                                    // value={editFormikFunction.values.specialAmount}
                                    // error={editFormikFunction.errors.specialAmount && editFormikFunction.touched.specialAmount ? true : false}
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* START DATE */}
                              <div className="col-span-1">
                                <div className="form-group">
                                  <label className="text-sm font-proxima-semibold text-black">
                                    Start Date
                                  </label>
                                  <div className="mt-1">
                                    <ReactDatePicker
                                      // selected={editFormikFunction.values.specialAmountStartDate}
                                      // error={editFormikFunction.errors.specialAmountStartDate && editFormikFunction.touched.specialAmountStartDate ? true : false}
                                      // onChange={(date) => editFormikFunction.setFieldValue("specialAmountStartDate", date)}
                                      calendarClassName="calendar"
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* END DATE */}
                              <div className="col-span-1">
                                <div className="form-group">
                                  <label className="text-sm font-proxima-semibold text-black">
                                    End Date
                                  </label>
                                  <div className="mt-1">
                                    <ReactDatePicker
                                      // selected={editFormikFunction.values.specialAmountEndDate}
                                      // error={editFormikFunction.errors.specialAmountEndDate && editFormikFunction.touched.specialAmountEndDate ? true : false}
                                      // onChange={(date) => editFormikFunction.setFieldValue("specialAmountEndDate", date)}
                                      calendarClassName="calendar"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    })}
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>


        {/* ADD COLOR DIALOG */}
        <Dialog
          open={colorAddDialog}
          handler={() => setColorAddDialog(!colorAddDialog)}
          size="lg"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
          className='overflow-y-scroll h-screen'
        >
          <DialogBody className="p-0 ">
            <form onSubmit={addColorFormikFunction.handleSubmit}>
              <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-y-5 xl:gap-5 lg:gap-5">
                <div className="col-span-3">
                  <div className="bg-white rounded-lg border border-authBorder">

                    <div className="block px-5 py-3 border-b border-authBorder xl:flex lg:flex items-center justify-between">
                      <div>
                        <h5 className="text-lg font-proxima-bold text-black">
                          Quantity, Color and Size Details
                        </h5>
                        <p className="text-sm font-proxima-regular text-greyText">
                          Fill in these product specific details
                        </p>
                      </div>
                      <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
                        <Button onClick={() => { setColorAddDialog(!colorAddDialog) }} className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                          Back
                        </Button>
                        <Button type="submit" disabled={loading} className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md border border-logoYellow text-sm font-proxima-semibold text-white normal-case transition-all duration-300 ease-in-out">
                          {loading ? "Please wait..." : "Save details"}
                        </Button>
                      </div>
                    </div>



                    <div className="p-5">

                      <div className="bg-white rounded-lg border border-authBorder">
                        <div className="p-5">
                          <div className="form-group">
                            <div className="grid grid-cols-6 gap-3">
                              {/* COLOR */}
                              <div className="col-span-6 xl:col-span-1 lg:col-span-1">
                                <div className="form-group">
                                  <label className="text-sm font-proxima-semibold text-black">
                                    Product Color{" "}
                                    <span className="text-red-500">*</span>
                                  </label>
                                  <div className="mt-1">
                                    <Select
                                      options={colorsList}
                                      placeholder="Select Color"
                                      name="color"
                                      id="color"
                                      classNamePrefix={addColorFormikFunction.errors.color && addColorFormikFunction.touched.color ? "dropdown_error" : "dropdown"}
                                      onChange={(event) => addColorFormikFunction.setFieldValue("color", event?.value)}
                                      value={colorsList?.find((el) => el?.value === addColorFormikFunction.values.color)}
                                    />
                                  </div>
                                </div>
                              </div>


                              {/* STATUS */}
                              <div className="col-span-6 xl:col-span-2 lg:col-span-2">
                                <div className="form-group">
                                  <label className="text-sm font-proxima-semibold text-black">
                                    Product Featured{" "}
                                    <span className="text-red-500">*</span>
                                  </label>
                                  <div className="mt-1">
                                    <Select
                                      options={isFeaturedOptions}
                                      classNamePrefix={addColorFormikFunction.errors.isFeatured && addColorFormikFunction.touched.isFeatured ? "dropdown_error" : "dropdown"}
                                      placeholder="Select Is Featured"
                                      name="isFeatured"
                                      id="isFeatured"
                                      onChange={(event) => addColorFormikFunction.setFieldValue("isFeatured", event?.value)}
                                      value={isFeaturedOptions?.find((el) => el?.value === addColorFormikFunction.values.isFeatured)}
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* SIZE */}
                              <div className="col-span-6 xl:col-span-3 lg:col-span-3">
                                <div className="form-group">
                                  <label className="text-sm font-proxima-semibold text-black">
                                    Product Available Sizes{" "}
                                    <span className="text-red-500">*</span>
                                  </label>
                                  <div className="mt-1">
                                    <Select
                                      options={sizeList}
                                      placeholder="Select Size"
                                      name="size"
                                      id="size"
                                      isMulti
                                      className='h-full'
                                      classNamePrefix={addColorFormikFunction.errors.size && addColorFormikFunction.touched.size ? "dropdown_error" : "dropdown"}
                                      onChange={(event) => addColorFormikFunction.setFieldValue("size", event)}
                                      value={addColorFormikFunction.values.size}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>



                        </div>

                      </div>

                      {availableSizeArray?.length > 0 && availableSizeArray?.map((element, index) => {

                        return <div key={index} className="bg-white rounded-lg border mt-3 border-authBorder">
                          <div className="px-5 py-3 border-b border-authBorder">
                            <h5 className="text-lg font-proxima-bold text-black capitalize">
                              {element?.sizeLabel} Size Quantity and Price
                            </h5>
                            <p className="text-sm font-proxima-regular text-greyText">
                              Attach the product images for this product
                            </p>
                          </div>
                          <div className="p-5">

                            <div className="form-group">
                              <div className="grid grid-cols-6 gap-3">
                                {/* SIZE */}
                                <div className="col-span-6 xl:col-span-1 lg:col-span-1">
                                  <div className="form-group">
                                    <label className="text-sm font-proxima-semibold text-black">
                                      Size{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <div className="mt-1">
                                      <InputField
                                        type="text"
                                        name="sku"
                                        id="sku"
                                        disabled={true}
                                        value={element?.sizeLabel}
                                        placeholder="Size"
                                      />
                                    </div>
                                  </div>
                                </div>

                                {/* SKU */}
                                <div className="col-span-6 xl:col-span-2 lg:col-span-2">
                                  <div className="form-group">
                                    <label className="text-sm font-proxima-semibold text-black">
                                      SKU{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <div className="mt-1">
                                      <InputField
                                        type="text"
                                        name="sku"
                                        id="sku"
                                        onChange={(event) => {
                                          handleChangeColorSizeAndQuantity("sku", element?.size, event?.target?.value)
                                        }}
                                        value={element?.sku}
                                        placeholder="SKU"
                                      />
                                    </div>
                                  </div>
                                </div>

                                {/* AMOUNT */}
                                <div className="col-span-6 xl:col-span-1 lg:col-span-1">
                                  <div className="form-group">
                                    <label className="text-sm font-proxima-semibold text-black">
                                      Amount{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <div className="mt-1">
                                      <InputField
                                        type="number"
                                        name="amount"
                                        id="amount"
                                        onChange={(event) => {
                                          handleChangeColorSizeAndQuantity("amount", element?.size, event?.target?.value)
                                        }}
                                        value={element?.amount}
                                        placeholder="Amount"
                                      />
                                    </div>
                                  </div>
                                </div>

                                {/* QUANTITY */}
                                <div className="col-span-6 xl:col-span-1 lg:col-span-1">
                                  <div className="form-group">
                                    <label className="text-sm font-proxima-semibold text-black">
                                      Quantity{" "}
                                      <span className="text-red-500">*</span>
                                    </label>
                                    <div className="mt-1">
                                      <InputField
                                        type="number"
                                        name="quantity"
                                        id="quantity"
                                        onChange={(event) => {
                                          handleChangeColorSizeAndQuantity("quantity", element?.size, event?.target?.value)
                                        }}
                                        value={element?.quantity}
                                        placeholder="Quantity"
                                      />
                                    </div>
                                  </div>
                                </div>

                                {/* STATUS */}
                                <div className="col-span-6 xl:col-span-1 lg:col-span-1">
                                  <label className="text-sm font-proxima-semibold text-black">
                                    Product Status{" "}
                                    <span className="text-red-500">*</span>
                                  </label>
                                  <div className="mt-1">
                                    <Select
                                      options={statusOptions}
                                      classNamePrefix={addColorFormikFunction.errors.status && addColorFormikFunction.touched.status ? "dropdown_error" : "dropdown"}
                                      placeholder="Select Status"
                                      name="status"
                                      id="status"
                                      onChange={(event) => {
                                        handleChangeColorSizeAndQuantity("status", element?.size, event)
                                      }}
                                      value={element?.status}
                                    />
                                  </div>
                                </div>

                                {/* SPECIAL DISCOUNTS */}

                                {/* SPECIAL AMOUNT */}
                                <div className="col-span-6 xl:col-span-2 lg:col-span-2">
                                  <div className="form-group">
                                    <label className="text-sm font-proxima-semibold text-black">
                                      Special Price
                                    </label>
                                    <div className="mt-1">
                                      <InputField
                                        type="number"
                                        name="specialAmount"
                                        id="specialAmount"
                                        placeholder="Write the special price"
                                      // onChange={editFormikFunction.handleChange}
                                      // value={editFormikFunction.values.specialAmount}
                                      // error={editFormikFunction.errors.specialAmount && editFormikFunction.touched.specialAmount ? true : false}
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* START DATE */}
                                <div className="col-span-1">
                                  <div className="form-group">
                                    <label className="text-sm font-proxima-semibold text-black">
                                      Start Date
                                    </label>
                                    <div className="mt-1">
                                      <ReactDatePicker
                                        // selected={editFormikFunction.values.specialAmountStartDate}
                                        // error={editFormikFunction.errors.specialAmountStartDate && editFormikFunction.touched.specialAmountStartDate ? true : false}
                                        // onChange={(date) => editFormikFunction.setFieldValue("specialAmountStartDate", date)}
                                        calendarClassName="calendar"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* END DATE */}
                                <div className="col-span-1">
                                  <div className="form-group">
                                    <label className="text-sm font-proxima-semibold text-black">
                                      End Date
                                    </label>
                                    <div className="mt-1">
                                      <ReactDatePicker
                                        // selected={editFormikFunction.values.specialAmountEndDate}
                                        // error={editFormikFunction.errors.specialAmountEndDate && editFormikFunction.touched.specialAmountEndDate ? true : false}
                                        // onChange={(date) => editFormikFunction.setFieldValue("specialAmountEndDate", date)}
                                        calendarClassName="calendar"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>

                        </div>
                      })}

                      {console.log("Err", addColorFormikFunction.errors)}
                      {console.log("Value", addColorFormikFunction.values)}

                    </div>
                  </div>
                </div>

              </div>
            </form>
          </DialogBody>
        </Dialog>

        {/* ADD IMAGE FOR COLOR */}
        <Dialog
          open={imageAddDialog}
          handler={() => setImageAddDialog(!imageAddDialog)}
          size="lg"
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogBody className="p-0">
            <form >
              {/* PRODUCT IMAGES */}
              <div className=" bg-white rounded-lg border border-authBorder">

                <div className="border-b border-authBorder px-5 py-3 flex items-center justify-between">
                  <div>
                    <h5 className="text-lg font-proxima-bold text-black">
                      Product Images
                    </h5>
                    <p className="text-sm font-proxima-regular text-greyText">
                      Attach the product images for this product
                    </p>
                  </div>
                  <Button
                    className="p-2 rounded-full bg-white shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5"
                    onClick={() => setImageAddDialog(!imageAddDialog)}
                  >
                    <IoClose onClick={() => setImageAddDialog(false)} className="w-4 h-4 text-greyText" />
                  </Button>
                </div>

                {/* FRONT IMAGE */}
                <div className="p-5">
                  <div className="block xl:flex lg:flex items-center justify-between">
                    <div>
                      <h5 className="text-lg font-proxima-bold text-black mb-3">
                        Front Image
                      </h5>
                    </div>
                    {productFrontImages?.length > 0 && <div className="flex items-center gap-2  xl:mt-0 lg:mt-0">
                      <Button onClick={() => { handleAddProductImage("front") }} disabled={uploading} className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-4 py-2 rounded-md border border-logoYellow text-xs font-proxima-semibold text-white normal-case transition-all duration-300 ease-in-out">
                        {uploading ? "Please wait..." : "Upload Image"}
                      </Button>
                    </div>}
                  </div>
                  {productDetails?.quantity?.find((el) => el?._id === selectedQuantityId)?.image?.front?.url ? <div className="grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 gap-5"><div className="col-span-1 relative" >
                    <img
                      src={IMAGE_URL + productDetails?.quantity?.find((el) => el?._id === selectedQuantityId)?.image?.front?.url}
                      className="w-full h-productImage object-cover rounded-md"
                      alt="Product"
                    />
                    <div className="absolute top-2 right-2">
                      <Button
                        className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                        onClick={() => handleRemoveImage("front")}
                      >
                        <FiTrash className="w-3 h-3 text-black" />
                      </Button>
                    </div>
                  </div></div> :
                    <ReactImageUploading
                      multiple
                      value={productFrontImages}
                      onChange={onFrontImageChange}
                      maxNumber={5}
                      dataURLKey="data_url"
                    >
                      {({ imageList, onImageUpload, onImageRemove }) => (
                        <div className="grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                          <div className="col-span-1">
                            <div
                              className="w-full h-productImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder cursor-pointer hover:border-logoYellow transition-all duration-300 ease-in-out p-5"
                              onClick={onImageUpload}
                            >
                              <AiFillPlusCircle className="w-8 h-8 text-greyText mx-auto" />
                              <div className="mt-2">
                                <p className="text-xs font-proxima-semibold text-greyText text-center">
                                  Click here to upload images
                                </p>
                                <p className="text-xs font-proxima-regular text-greyText text-center mt-1">
                                  (JPG, JPEG, PNG)
                                </p>
                              </div>
                            </div>
                          </div>
                          {imageList.map((image, index) => (
                            <div className="col-span-1 relative" key={index}>
                              <img
                                src={image["data_url"]}
                                className="w-full h-productImage object-cover rounded-md"
                                alt="Product"
                              />
                              <div className="absolute top-2 right-2">
                                <Button
                                  className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                                  onClick={() => onImageRemove(index)}
                                >
                                  <FiTrash className="w-3 h-3 text-black" />
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ReactImageUploading>}
                </div>

                {/* BACK IMAGE */}
                <div className="p-5 border-t border-authBorder">

                  <div className="block xl:flex lg:flex items-center justify-between">
                    <div>
                      <h5 className="text-lg font-proxima-bold text-black mb-3">
                        Back Image
                      </h5>
                    </div>
                    {productBackImages?.length > 0 && <div className="flex items-center gap-2  xl:mt-0 lg:mt-0">
                      <Button onClick={() => { handleAddProductImage("back") }} disabled={uploading} className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-4 py-2 rounded-md border border-logoYellow text-xs font-proxima-semibold text-white normal-case transition-all duration-300 ease-in-out">
                        {uploading ? "Please wait..." : "Upload Image"}
                      </Button>
                    </div>}
                  </div>
                  {productDetails?.quantity?.find((el) => el?._id === selectedQuantityId)?.image?.back?.url ? <div className="grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 gap-5"><div className="col-span-1 relative" >
                    <img
                      src={IMAGE_URL + productDetails?.quantity?.find((el) => el?._id === selectedQuantityId)?.image?.back?.url}
                      className="w-full h-productImage object-cover rounded-md"
                      alt="Product"
                    />
                    <div className="absolute top-2 right-2">
                      <Button
                        className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                        onClick={() => handleRemoveImage("back")}
                      >
                        <FiTrash className="w-3 h-3 text-black" />
                      </Button>
                    </div>
                  </div></div> :
                    <ReactImageUploading
                      multiple
                      value={productBackImages}
                      onChange={onBackImageChange}
                      maxNumber={5}
                      dataURLKey="data_url"
                    >
                      {({ imageList, onImageUpload, onImageRemove }) => (
                        <div className="grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                          <div className="col-span-1">
                            <div
                              className="w-full h-productImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder cursor-pointer hover:border-logoYellow transition-all duration-300 ease-in-out p-5"
                              onClick={onImageUpload}
                            >
                              <AiFillPlusCircle className="w-8 h-8 text-greyText mx-auto" />
                              <div className="mt-2">
                                <p className="text-xs font-proxima-semibold text-greyText text-center">
                                  Click here to upload images
                                </p>
                                <p className="text-xs font-proxima-regular text-greyText text-center mt-1">
                                  (JPG, JPEG, PNG)
                                </p>
                              </div>
                            </div>
                          </div>
                          {imageList.map((image, index) => (
                            <div className="col-span-1 relative" key={index}>
                              <img
                                src={image["data_url"]}
                                className="w-full h-productImage object-cover rounded-md"
                                alt="Product"
                              />
                              <div className="absolute top-2 right-2">
                                <Button
                                  className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                                  onClick={() => onImageRemove(index)}
                                >
                                  <FiTrash className="w-3 h-3 text-black" />
                                </Button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ReactImageUploading>}
                </div>

                {/* OTHER IMAGES */}
                <div className="p-5 border-t border-authBorder">
                  <div className="block xl:flex lg:flex items-center justify-between">
                    <div>
                      <h5 className="text-lg font-proxima-bold text-black mb-3">
                        Other Image
                      </h5>
                    </div>
                    {productOthersImages?.length > 0 && <div className="flex items-center gap-2 mb-2 xl:mt-0 lg:mt-0">
                      <Button onClick={() => { handleAddProductImage("others") }} disabled={uploading} className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-4 py-2 rounded-md border border-logoYellow text-xs font-proxima-semibold text-white normal-case transition-all duration-300 ease-in-out">
                        {uploading ? "Please wait..." : "Upload Image"}
                      </Button>
                    </div>}
                  </div>

                  <ReactImageUploading
                    multiple
                    value={productOthersImages}
                    onChange={onOtherImageChange}
                    maxNumber={5}
                    dataURLKey="data_url"
                  >
                    {({ imageList, onImageUpload, onImageRemove }) => (
                      <div className="grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                        {productDetails?.quantity?.find((el) => el?._id === selectedQuantityId)?.image?.others?.map((element, index) => {
                          return <div className="col-span-1 relative" key={index}>
                            <img
                              src={IMAGE_URL + element?.url}
                              className="w-full h-productImage object-cover rounded-md"
                              alt="Product"
                            />
                            <div className="absolute top-2 right-2">
                              <Button
                                className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                                onClick={() => handleRemoveImage(element?._id)}
                              >
                                <FiTrash className="w-3 h-3 text-black" />
                              </Button>
                            </div>
                          </div>
                        })}
                        <div className="col-span-1">
                          <div
                            className="w-full h-productImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder cursor-pointer hover:border-logoYellow transition-all duration-300 ease-in-out p-5"
                            onClick={onImageUpload}
                          >
                            <AiFillPlusCircle className="w-8 h-8 text-greyText mx-auto" />
                            <div className="mt-2">
                              <p className="text-xs font-proxima-semibold text-greyText text-center">
                                Click here to upload images
                              </p>
                              <p className="text-xs font-proxima-regular text-greyText text-center mt-1">
                                (JPG, JPEG, PNG)
                              </p>
                            </div>
                          </div>
                        </div>
                        {imageList.map((image, index) => (
                          <div className="col-span-1 relative" key={index}>
                            <img
                              src={image["data_url"]}
                              className="w-full h-productImage object-cover rounded-md"
                              alt="Product"
                            />
                            <div className="absolute top-2 right-2">
                              <Button
                                className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                                onClick={() => onImageRemove(index)}
                              >
                                <FiTrash className="w-3 h-3 text-black" />
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ReactImageUploading>
                </div>
              </div>
            </form>
          </DialogBody>

        </Dialog>

      </div>
    </section>
  );
};

export default EditProduct;
