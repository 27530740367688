const SERVER_URL = "https://fashionnfun-backend-nodejs-development-2mk6mheziq-uc.a.run.app/admin/";
const IMAGE_URL = "https://storage.googleapis.com/fashion-and-fun/";
const SET_FORMAT = (amount) => {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return formatter.format(amount);
};
module.exports = {
  SERVER_URL,
  IMAGE_URL,
  SET_FORMAT,
};
