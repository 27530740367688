/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Helmet } from "react-helmet";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Tooltip,
} from "@material-tailwind/react";

/* ICONS */
import { FaClipboardUser } from "react-icons/fa6";
import { IoIosArrowForward } from "react-icons/io";
import { IoCart, IoEyeSharp, IoPrint } from "react-icons/io5";
import { TbFileInvoice } from "react-icons/tb";

/* USER DEFINED COMPONENTS */
import Input from "../../../components/input";

/* SAMPLE DATA */
import moment from "moment/moment";
import { useEffect } from 'react';
import { GrNext, GrPrevious } from "react-icons/gr";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { SET_FORMAT } from "../../../config";
import { getOrdersList, getUsersList } from "../../../service/api";

/* TILES */
const tilesOptions = [
  { id: 1, value: "Personal Details", icon: FaClipboardUser },
  { id: 2, value: "Previous Orders", icon: IoCart },
  { id: 3, value: "Invoices", icon: IoPrint },
];

const Customers = () => {
  const [activeTile, setActiveTile] = useState("Personal Details");
  const [usersList, setUsersList] = useState([]);
  const [ordersList, setOrdersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageForTable, setPageForTable] = useState(1);
  const [sizePerPageForTable, setSizePerPageForTable] = useState(5);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [activeUserId, setActiveUserId] = useState(null);

  /* ORDER COLUMNS */
  const columns = [
    {
      name: "Order ID",
      grow: 1,
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row?.orderId}
        </span>
      ),
    },
    {
      name: "Product",
      width: "25vh",
      selector: (row) => (
        <span className="text-sm font-proxima-bold text-black">
          {row?.order?.map((element, index) => {
            return (
              <span key={index}>
                {element?.product?.name}
                {index !== row?.order?.length - 1 && ", "}
              </span>
            );
          })}
        </span>
      ),
    },
    {
      name: "Purchase Date",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {moment(row.createdAt).format("DD MMM' YYYY hh:mm a")}
        </span>
      ),
    },
    {
      name: "Amount",
      width: "10vh",
      selector: (row) => (
        <span className="text-sm font-proxima-bold text-black">
          {SET_FORMAT(row?.amount)}
        </span>
      ),
    },
    {
      name: "Actions",
      width: "10vh",
      selector: (row) => (
        <Link to={`/order/${row?._id}`}>
          <div className="flex items-center">
            <Tooltip
              content="View order details"
              className="text-sm font-proxima-semibold"
            >
              <Button className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
                <IoEyeSharp className="w-5 h-5 text-greyText" />
              </Button>
            </Tooltip>
          </div>
        </Link>
      ),
    },
  ];

  /* INVOICE COLUMNS */
  const invoiceColumns = [
    {
      name: "Payment ID",
      width: "15vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row?.paymentId}
        </span>
      ),
    },
    {
      name: "Invoice Date",
      width: "20vh",
      selector: (row) => (
        <span className="text-sm font-proxima-bold text-black">
          {moment(row?.createdAt).format("DD MMM' YYYY hh:mm a")}
        </span>
      ),
    },
    {
      name: "Amount",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {SET_FORMAT(row?.amount)}
        </span>
      ),
    },
    {
      name: "Status",
      width: "10vh",
      selector: (row) =>
        row.paymentStatus === "Cash on Delivery" ? (
          <span className="text-sm font-proxima-semibold text-orange-800">
            {row?.paymentStatus}
          </span>
        ) : (
          <span className="text-sm font-proxima-semibold text-green-500">
            {row?.paymentStatus}
          </span>
        ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <Link to={`/invoice/${row?._id}`}>
          <div className="flex items-center">
            <Tooltip
              content="View invoice"
              className="text-sm font-proxima-semibold"
            >
              <Button className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
                <TbFileInvoice className="w-5 h-5 text-greyText" />
              </Button>
            </Tooltip>
          </div>
        </Link>
      ),
    },
  ];

  /* GET INITIALS */
  const getInitials = (name) => {
    var parts = name.split(" ");
    var initials = "";
    for (var i = 0; i < parts.length; i++) {
      if (parts[i].length > 0 && parts[i] !== "") {
        initials += parts[i][0];
      }
    }
    return initials;
  };

  /* INVOICE TILE */
  const InvoiceTile = (props) => {
    return (
      <div>
        <h5 className="text-base font-proxima-regular text-greyText">
          Total Invoice Amount
        </h5>
        <h2 className="text-2xl font-proxima-semibold text-logoYellow">
          ₹
          {parseFloat(parseFloat(props.amount).toFixed(2)).toLocaleString(
            "en-IN"
          )}{" "}
          <span className="text-base font-proxima-semibold text-logoYellow">
            INR
          </span>
        </h2>
      </div>
    );
  };

  /* PROFILE TILES */
  const ProfileTile = (props) => {
    return (
      <div
        className={`${activeTile !== props.tileName &&
          "group"} flex items-center justify-between px-3 py-4 cursor-pointer mt-2 first:mt-0 ${activeTile ===
          props.tileName &&
          "bg-black bg-opacity-5"} rounded-md transition-all duration-300 ease-in-out`}
        onClick={() => setActiveTile(props.tileName)}
      >
        <div className="flex items-center gap-2">
          <props.icon className="w-5 h-5 text-greyText group-hover:text-logoYellow transition-all duration-300 ease-in-out" />
          <p className="text-sm font-proxima-semibold text-black group-hover:text-logoYellow leading-none transition-all duration-300 ease-in-out">
            {props.tileName}
          </p>
        </div>
        <IoIosArrowForward className="w-4 h-4 text-greyText group-hover:text-logoYellow transition-all duration-300 ease-in-out" />
      </div>
    );
  };

  /* PERSONAL INFORMATION */
  const PersonalInformation = (props) => {
    return (
      <div className="p-5 xl:p-10 lg:p-10">
        <h2 className="text-xl font-proxima-bold text-black">
          Personal Information
        </h2>
        <h3 className="text-base font-proxima-regular text-greyText">
          Basic information, like name, email and phone of Fashion N Fun
          customers
        </h3>
        <div className="mt-5 xl:mt-10 lg:mt-10">
          <div className="bg-greyBg rounded-md w-full py-2 px-5">
            <p className="text-sm font-proxima-semibold text-greyText">
              Basic Information
            </p>
          </div>
          <div className="grid grid-cols-2 border-b border-authBorder px-0 py-5 xl:p-5 lg:p-5">
            <div className="col-span-1">
              <p className="text-base font-proxima-regular text-greyText">
                Name
              </p>
            </div>
            <div className="col-span-1">
              <p className="text-base font-proxima-bold text-black">
                {props.name}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 border-b border-authBorder px-0 py-5 xl:p-5 lg:p-5">
            <div className="col-span-1">
              <p className="text-base font-proxima-regular text-greyText">
                Email address
              </p>
            </div>
            <div className="col-span-1">
              <p
                className="text-base font-proxima-bold text-black"
                style={{ overflowWrap: "anywhere" }}
              >
                {props.email}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 px-0 py-5 xl:p-5 lg:p-5">
            <div className="col-span-1">
              <p className="text-base font-proxima-regular text-greyText">
                Phone number
              </p>
            </div>
            <div className="col-span-1">
              <p className="text-base font-proxima-bold text-black">
                {props.phone}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  /* ORDERS */
  const Orders = () => {
    return (
      <div className="p-5 xl:p-10 lg:p-10">
        <h2 className="text-xl font-proxima-bold text-black">
          Previous Orders
        </h2>
        <h3 className="text-base font-proxima-regular text-greyText">
          Products ordered by this customer previously from Fashion N Fun
        </h3>

        {/* ORDERS TABLE */}
        <div className="mt-5">
          <DataTable
            className="data-table"
            data={ordersList.docs}
            columns={columns}
            responsive
            pagination
            paginationServer
            progressComponent={<LoadingScreen />}
            progressPending={loading}
            paginationTotalRows={totalDocs}
            onChangePage={handlePageChangeForTable}
            onChangeRowsPerPage={handlePerPageChange}
            noDataComponent={<NoDataScreen />}
          />
        </div>
      </div>
    );
  };

  /* INVOICES */
  const Invoices = () => {
    return (
      <div className="p-5 xl:p-10 lg:p-10">
        <h2 className="text-xl font-proxima-bold text-black">
          Previous Invoices
        </h2>
        <h3 className="text-base font-proxima-regular text-greyText">
          Manage invoices of this customer
        </h3>

        {/* ORDERS TABLE */}
        <div className="mt-5">
          <DataTable
            className="data-table"
            data={ordersList.docs}
            columns={invoiceColumns}
            responsive
            pagination
            paginationServer
            progressComponent={<LoadingScreen />}
            progressPending={loading}
            paginationTotalRows={totalDocs}
            onChangePage={handlePageChangeForTable}
            onChangeRowsPerPage={handlePerPageChange}
            noDataComponent={<NoDataScreen />}
          />
        </div>
      </div>
    );
  };

  const LoadingScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          Please wait!
        </p>
        <p className="text-sm font-proxima-semibold text-black text-center">
          We are fetching data{" "}
        </p>
      </div>
    );
  };

  const NoDataScreen = () => {
    return (
      <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
        <p className="text-sm font-proxima-semibold text-black text-center">
          There are no queries to display.
        </p>
      </div>
    );
  };

  const getUsersListFunction = () => {
    setLoading(true);
    const params = {
      page: page,
      sizePerPage: 5,
    };

    getUsersList(params)
      .then((res) => {
        if (res?.status) {
          setUsersList(res?.data?.docs);
          setTotalPages(res?.data?.totalPages);
          if (res?.data?.docs?.length > 0) {
            setActiveUserId(res?.data?.docs[0]._id);
          }
        }
      })
      .catch((e) => {
        console.log("e-->", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getOrdersListFunction = () => {
    setLoading(true);
    const params = {
      page: pageForTable,
      sizePerPage: sizePerPageForTable,
      userId: activeUserId,
    };

    getOrdersList(params)
      .then((res) => {
        if (res?.status) { 
          setOrdersList(res?.data);
          setTotalDocs(res?.docs?.totalDocs)
        }
      })
      .catch((e) => {
        console.log("e-->", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = async (e) => {
    const selectedPage = e.selected + 1;
    setPage(selectedPage);
    getUsersListFunction();
  };

  const handlePageChangeForTable = async (e) => {
    setPageForTable(e);
    getOrdersListFunction();
  }

  const handlePerPageChange = async (e) => {
    setSizePerPageForTable(e);
    getOrdersListFunction();
  };

  useEffect(() => {
    getUsersListFunction();
  }, []);

  useEffect(() => {
    if (activeUserId) {
      getOrdersListFunction(activeUserId);
    }
  }, [activeUserId]);

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Customers | Fashion N Fun</title>
      </Helmet>

      <div className="container-full-width">
        <div className="block xl:flex lg:flex items-center justify-between">
          {/* HEADING */}
          <div>
            <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
              Fashion N Fun Customers
            </h1>
            <h2 className="text-lg font-proxima-regular text-greyText tracking-tight">
              Manage the customers who have created the account on your website
            </h2>
          </div>

          {/* SEARCH AND FILTERS */}
          <div className="mt-2 xl:mt-0 lg:mt-0">
            {/* SEARCHBAR */}
            <div className="w-full xl:w-searchBar lg:w-searchBar">
              <Input
                type="text"
                placeholder="Search by customer"
                name="searchCustomer"
                id="searchCustomer"
              />
            </div>
          </div>
        </div>

        {/* CUSTOMERS LIST */}
        { usersList.length > 0 ? <div className="mt-5 xl:mt-10 lg:mt-10 border">
          <Tabs
            value={usersList[0]?.firstName+ " " + usersList[0]?.lastName}
            orientation="vertical"
            className="w-full block overflow-visible"
          >
            <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-y-5 xl:gap-5 lg:gap-5">
              {/* CUSTOMERS LIST */}
              <div className="col-span-1">
                <div className="max-h-customerList bg-white border border-authBorder rounded-md p-3 overflow-y-auto overflow-x-hidden hide-scrollbar">
                  <TabsHeader
                    className="rounded-md bg-transparent p-0"
                    indicatorProps={{
                      className:
                        "border border-logoYellow bg-logoYellow bg-opacity-10",
                    }}
                  >
                    {usersList?.map((data) => (
                      <Tab
                        value={data?.firstName + " " + data?.lastName}
                        className="flex items-center justify-between detail-tab p-3 w-full mt-2 first:mt-0"
                        key={data._id}
                        onClick={() => {
                          setActiveUserId(data?._id);
                          console.log(data?._id);
                        }}
                      >
                        <div className="flex items-center gap-2">
                          <div className="w-10 h-10 bg-logoYellow rounded-full flex items-center justify-center">
                            <p className="text-sm font-proxima-bold text-white leading-none">
                              {getInitials(data?.firstName+ " " +data?.lastName)}
                            </p>
                          </div>
                          <div>
                            <h5 className="text-base font-proxima-semibold text-black leading-tight">
                              {data?.firstName + " " + data?.lastName}
                            </h5>
                            <p className="text-sm font-proxima-regular text-greyText">
                              {data?.email}
                            </p>
                          </div>
                        </div>
                        <IoIosArrowForward className="w-4 h-4 text-greyText" />
                      </Tab>
                    ))}
                  </TabsHeader>
                </div>
                {usersList?.length > 0 && <ReactPaginate
                className="lists-pagination"
                previousLabel={
                  <Button className="list-pagination-btn">
                    <GrPrevious color="#323232" size="20" />
                  </Button>
                }
                nextLabel={
                  <Button className="list-pagination-btn">
                    <GrNext color="#323232" size="20" />
                  </Button>
                }
                breakLabel={"..."}
                pageCount={totalPages}
                marginPagesDisplayed={3}
                pageRangeDisplayed={2}
                onPageChange={handlePageChange}
                activeClassName={"active"}
              />}
              </div>

              {/* CUSTOMER DETAIL SECTION */}
              <div className="col-span-3">
                <TabsBody className="p-0">
                  {/* CUSTOMER TABPANEL */}
                  {usersList.map((data) => (
                  <TabPanel key={data._id} value={data?.firstName + " " + data?.lastName} className="p-0">
                    <div className="h-customerList max-h-[68vh] bg-white border border-authBorder rounded-md overflow-y-auto overflow-x-hidden hide-scrollbar">
                      <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 h-full">
                        <div className="col-span-1 h-full">
                          <div className="border-r border-authBorder h-full">
                            {/* INVOICE TILE */}
                            <div className="border-b border-authBorder px-7 py-3">
                              <InvoiceTile amount="2567.96" />
                            </div>

                            {/* TILES */}
                            <div className="p-5">
                              {tilesOptions.map((tile) => (
                                <React.Fragment key={tile.id}>
                                  <ProfileTile
                                    tileName={tile.value}
                                    icon={tile.icon}
                                  />
                                </React.Fragment>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="col-span-2 h-full">
                          {activeTile === "Personal Details" && (
                            <PersonalInformation
                              name={data?.firstName + " " + data?.lastName}
                              email={data?.email}
                              phone={data?.phoneNumber}
                            />
                          )}
                          {activeTile === "Previous Orders" && <Orders userId={data?._id} />}
                          {activeTile === "Invoices" && <Invoices userId={data?._id} />}
                        </div>
                      </div>
                    </div>
                    </TabPanel>
                    ))}
                </TabsBody>
              </div>
            </div>
          </Tabs>
        </div>: <LoadingScreen/>}
      </div>
    </section>
  );
};

export default Customers;
