/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import DataTable from "react-data-table-component";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* ICONS */
import { TbFileInvoice } from "react-icons/tb";

/* SAMPLE DATA */
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toast";
import { IMAGE_URL, SET_FORMAT } from "../../../../config";
import { getOrderDetailsByIdApi } from "../../../../service/api";

const OrderDetails = () => {
  const [orderDetails, setOrderDetails] = useState();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  console.log(orderDetails?.order[0]?.product?.quantity[0]?.image?.front?.url);

  const handleGetOrderDetails = () => {
    setLoading(true);
    getOrderDetailsByIdApi(id)
      .then((res) => {
        toast.success(res?.message);
        setOrderDetails(res?.data);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      }).finally(() => {
        setLoading(false);
      })
  }

  useEffect(() => {
    handleGetOrderDetails();
  }, [id]);

  /* ORDER COLUMNS */ 
  const columns = [
    {
      name: "Image",
      width: "15vh",
      selector: (row) => (
        <span>
          <img
            src={IMAGE_URL+row?.product?.quantity[0]?.image?.front?.url}     
            className="w-20 h-20 rounded-lg object-cover"
            alt={row?.name}
          />
        </span>
      ),
    },
    {
      name: "SKU",
      width: "10vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row?.product?.sku}
        </span>
      ),
    },
    {
      name: "Product Name",
      width: "35vh",
      selector: (row) => (
        <span className="text-sm font-proxima-bold text-black">
          {row?.product?.name}
        </span>
      ),
    },
    {
      name: "Price",
      width: "15vh",
      selector: (row) => (
        <span className="text-sm font-proxima-bold text-black">
          {SET_FORMAT(row?.amount)}
        </span>
      ),
    },
    {
      name: "Quantity",
      width: "15vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row?.quantity}
        </span>
      ),
    },
    {
      name: "Total Price",
      width: "15vh",
      selector: (row) => (
        <span className="text-sm font-proxima-bold text-black">
          {SET_FORMAT(row?.amount * row?.quantity)}
        </span>
      ),
    },
  ];

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Order Details | Fashion N Fun</title>
      </Helmet>
      
      <div className="container-full-width">
        <div className="block xl:flex lg:flex items-center justify-between">
          {/* HEADING */}
          <div>
            <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
              Order Details
            </h1>
            <h2 className="text-lg font-proxima-regular text-greyText tracking-tight">
              Manage the order details, order confirmation and delivery details
              have been sent to{" "}
              <span className="font-proxima-bold">
                {orderDetails?.email}
              </span>
            </h2>
          </div>

          {/* VIEW INVOICE */}
          <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
            <Link to={'/orders'}>
              <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                Back to Orders
              </Button>
            </Link>
            <Link to={`/invoice/${orderDetails?._id}`}>
            <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal justify-center shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md border border-logoYellow text-sm font-proxima-semibold text-white normal-case transition-all duration-300 ease-in-out flex">
              <TbFileInvoice className="w-5 h-5 mr-1" /> View Invoice
              </Button>
            </Link>
          </div>
        </div>

        {!orderDetails && loading === true && (
          <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5 mt-3">
            <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight text-center">Please wait we are fetching your categories...</p>
          </div>
        )}
        {!orderDetails && loading === false && (
          <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5 mt-3">
            <p className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight text-center">We were not able to find any categories.</p>
          </div>
        )}

        {/* CUSTOMERS LIST */}
        { loading ? null :
        <div className="mt-5 xl:mt-5 lg:mt-5">
          <div className=" bg-white border border-authBorder rounded-md overflow-y-auto overflow-x-hidden hide-scrollbar">
            <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 h-full">
              <div className="col-span-3 h-full order-2 xl:order-1 lg:order-1">
                <div className="border-r border-authBorder h-full">
                  <div className="p-5 xl:p-5 lg:p-5">
                    <div className="grid grid-cols-12 h-full gap-5 pb-3 ">
                      {/* SHIPPING INFORMATION */}
                      <div className="col-span-12 lg:col-span-6 px-3 py-3 ">
                        <h2 className="text-xl font-proxima-bold text-black">
                          Shipping details
                        </h2>
                        <h3 className="text-base font-proxima-regular text-greyText border-b border-authBorder pb-2">
                          Shipping details include recipient information
                          necessary for smooth and efficient order delivery.
                        </h3>

                        <p className="text-base font-proxima-regular text-greyText mt-3">
                          {orderDetails?.shippingDetails?.firstName + " " + orderDetails?.shippingDetails?.lastName}
                        </p>
                        <p className="text-base font-proxima-regular text-greyText">
                          {orderDetails?.shippingDetails?.email}
                        </p>
                        <p className="text-base font-proxima-regular text-greyText">
                          {orderDetails?.shippingDetails?.phoneNumber}
                        </p>
                        <p className="text-base font-proxima-regular text-greyText mt-2">
                          {orderDetails?.shippingDetails?.address}
                        </p>
                        <p className="text-base font-proxima-regular text-greyText ">
                          {orderDetails?.shippingDetails?.city}, {orderDetails?.shippingDetails?.state}, {orderDetails?.shippingDetails?.zipCode}, {orderDetails?.shippingDetails?.country}
                        </p>

                        <h3 className="text-xl font-proxima-bold text-black border-b border-authBorder pb-2 mt-5">
                          Order information
                        </h3>

                        <p className="text-base font-proxima-regular text-greyText mt-2">
                          <span className="text-black font-proxima-bold">
                            Shipping Type :&nbsp;
                          </span>
                          {orderDetails?.shippingType}&nbsp;
                          <span className="text-black font-proxima-bold">
                          ( {SET_FORMAT(orderDetails?.shippingCharge)} )
                          </span>
                        </p>
                        <p className="text-lg font-proxima-regular text-[#FFB129]  mt-2">
                          <span className="text-base text-black font-proxima-bold">
                            Order Status :
                          </span>{" "}
                          {orderDetails?.orderStatus}
                        </p>
                      </div>

                      {/* BILLING INFORMATION */}
                      <div className="col-span-12 lg:col-span-6 px-3 py-3">
                        <h2 className="text-xl font-proxima-bold text-black">
                          Billing details
                        </h2>
                        <h3 className="text-base font-proxima-regular text-greyText border-b border-authBorder pb-2">
                          Billing details, including payment method and
                          transaction specifics, ensure accurate and complete
                          order processing.
                        </h3>

                        <p className="text-base font-proxima-regular text-greyText mt-3">
                          {orderDetails?.billingDetails?.firstName + " " + orderDetails?.billingDetails?.lastName}
                        </p>
                        <p className="text-base font-proxima-regular text-greyText">
                          {orderDetails?.billingDetails?.email}
                        </p>
                        <p className="text-base font-proxima-regular text-greyText">
                          {orderDetails?.billingDetails?.phoneNumber}
                        </p>
                        <p className="text-base font-proxima-regular text-greyText mt-2">
                          {orderDetails?.billingDetails?.address}
                        </p>
                        <p className="text-base font-proxima-regular text-greyText ">
                          {orderDetails?.billingDetails?.city}, {orderDetails?.billingDetails?.state}, {orderDetails?.billingDetails?.zipCode}, {orderDetails?.billingDetails?.country}
                        </p>

                        <h3 className="text-xl font-proxima-bold text-black border-b border-authBorder pb-2 mt-5">
                          Payment information
                        </h3>

                        <p className="text-base font-proxima-regular text-greyText mt-2">
                          <span className="text-black font-proxima-bold">
                            Payment Method :
                          </span>{" "}
                          {orderDetails?.paymentMode} {" "}
                          <span className="text-black font-proxima-bold">
                            ( ID : {orderDetails?.paymentId} )
                          </span>
                        </p>
                        <p className=" text-lg font-proxima-regular text-green-700 mt-2">
                          <span className="text-base text-black font-proxima-bold">
                            Payment Status :
                          </span>{" "}
                          {orderDetails?.paymentStatus}
                        </p>
                      </div>
                    </div>

                    {/* PRODUCTS */}
                    <div className="grid grid-cols-12 h-full gap-5 pt-3 border-t border-authBorder ">
                      {/* SHIPPING INFORMATION */}
                      <div className="col-span-12 px-3 py-3">
                        <h2 className="text-xl font-proxima-bold text-black">
                          Products ({orderDetails?.order?.length})
                        </h2>
                        {/* <h3 className="text-base font-proxima-regular text-greyText  pb-2">
                                                Shipping details include recipient information necessary for smooth and efficient order delivery.
                                            </h3> */}

                        {/* ORDERS TABLE */}
                        <div className="mt-3">
                          <DataTable
                            className="data-table"
                            data={orderDetails?.order}
                            columns={columns}
                            responsive
                            paginationTotalRows={15}
                            noDataComponent={
                              <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
                                <p className="text-sm font-proxima-semibold text-black text-center">
                                  There are no Products to display.
                                </p>
                              </div>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-1 h-full order-1 xl:order-2 lg:order-2">
                <div className="p-5 xl:p-5 lg:p-5">
                  <div className="grid grid-cols-6 border-b border-authBorder px-0 py-5 xl:px-2 lg:px-2">
                    <div className="col-span-4">
                      <h2 className="text-xl font-proxima-bold text-black">
                        Order ID {orderDetails?.orderId}
                      </h2>
                      <h3 className=" text-sm font-proxima-regular text-greyText  pb-2">
                        {moment(orderDetails?.createdAt).format("DD MMM' YYYY hh:mm a")}
                      </h3>
                    </div>
                    <div className="col-span-2 ml-1">
                      <span class="bg-green-100  text-green-800 text-xs font-medium  p-2 rounded dark:bg-green-900 dark:text-green-300">
                        {orderDetails?.orderStatus}
                      </span>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="bg-greyBg rounded-md w-full py-2 px-5">
                      <p className="text-sm font-proxima-semibold text-greyText">
                        Summary
                      </p>
                    </div>

                    {/* SUBTOTAL */}
                    <div className="grid grid-cols-2 border-b border-authBorder px-5 py-5 xl:p-5 lg:p-5">
                      <div className="col-span-1">
                        <p className="text-base font-proxima-regular text-greyText">
                          Subtotal
                        </p>
                      </div>
                      <div className="col-span-1">
                        <p className="text-base font-proxima-bold text-black">
                          {SET_FORMAT(orderDetails?.amount)}
                        </p>
                      </div>
                    </div>

                    {/* DISCOUNT */}
                    <div className="grid grid-cols-2 border-b border-authBorder px-5 py-5 xl:p-5 lg:p-5">
                      <div className="col-span-1">
                        <p className="text-base font-proxima-regular text-greyText">
                          Discount
                        </p>
                      </div>
                      <div className="col-span-1">
                        <p className="text-base font-proxima-bold text-black">
                          {SET_FORMAT(orderDetails?.discountAmount)}
                        </p>
                      </div>
                      </div>
                      
                    {/* SHIPPING COST */}
                    {orderDetails?.shippingData?.freeShippingOption ? null :
                      <div className="grid grid-cols-2 border-b border-authBorder px-5 py-5 xl:p-5 lg:p-5">
                        <div className="col-span-1">
                          <p className="text-base font-proxima-regular text-greyText">
                            Shipment cost
                          </p>
                        </div>
                        <div className="col-span-1">
                          <p className="text-base font-proxima-bold text-black">
                            {SET_FORMAT(orderDetails?.shippingCharge)}
                          </p>
                        </div>
                      </div>
                    }

                    {/* TAX COST */}
                    <div className="grid grid-cols-2 border-b border-authBorder px-5 py-5 xl:p-5 lg:p-5">
                      <div className="col-span-1">
                        <p className="text-base font-proxima-regular text-greyText">
                          Tax cost
                        </p>
                      </div>
                      <div className="col-span-1">
                        <p className="text-base font-proxima-bold text-black">
                          {SET_FORMAT(orderDetails?.taxAmount)}
                        </p>
                      </div>
                      </div>
                      
                    {/* GRAND TOTAL */}
                    <div className="grid grid-cols-2  px-5 py-5 xl:p-5 lg:p-5">
                      <div className="col-span-1">
                        <p className="text-lg font-proxima-bold text-black">
                          Grand Total
                        </p>
                      </div>
                      <div className="col-span-1">
                        <p className="text-lg font-proxima-bold text-black">
                          {SET_FORMAT(orderDetails?.payAmount)}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="bg-greyBg rounded-md w-full py-2 px-5">
                      <p className="text-sm font-proxima-semibold text-greyText">
                        Notes
                      </p>
                    </div>
                    <p className="text-base font-proxima-regular p-3 text-greyText">
                      {orderDetails?.notes}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
         }
      </div>
    </section>
  );
};
export default OrderDetails;
