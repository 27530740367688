import ErrorImage from "../../assets/images/error-404.svg";

const ErrorPage = () => {

    return (
      <section className="bg-white">
        {/* AUTH FORM */}
        <div className="h-authForm">
          <div className="container h-full flex items-center justify-center">
            <div className="w-[60vh] bg-white rounded-lg  p-10">
              <img src={ErrorImage} className="w-auto h-auto" alt="Fashion N Fun" />
              <div className="mt-5 text-center">
                <h1 className="text-2xl font-proxima-bold text-black">
                Oops! Why you’re here?
                </h1>
                <h2 className="text-lg font-proxima-regular text-greyText mt-1">
                We are very sorry for inconvenience. It looks like you’re try to access a page that either has been
                deleted or never existed.
                </h2>
              </div>

              <p className="text-sm font-proxima-semibold text-black mt-3 text-center"> <a className=" text-[#000]" href="/dashboard"> Back To Home</a></p>
            </div>
          </div>
        </div>
  
      </section>
    );
  };
  
  export default ErrorPage;