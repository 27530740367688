/* eslint-disable no-unused-vars */
import { useState, useRef, useEffect } from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
import { DateRangePicker } from "react-date-range";
import { Button, Tooltip } from "@material-tailwind/react";
import DataTable from "react-data-table-component";

/* ICONS */
import { FaCalendar } from "react-icons/fa6";
import { IoEyeSharp } from "react-icons/io5";

/* CHARTS */
import { SplineChart, PieChart, PieChartForTopSellingCategory } from "../../../components/charts";

/* SAMPLE DATA */
const orders = [
  {
    orderID: "#001",
    product: "Organic Brown Rice",
    name: "Hittarth Solanki",
    email: "hittarth911@gmail.com",
    phone: "+91 9756389851",
    purchaseDate: "12/03/2024",
    amount: "1160.56",
    paymentStatus: "Cash on Delivery",
  },
  {
    orderID: "#002",
    product: "Cold-Pressed Virgin Coconut Oil",
    name: "Hittarth Solanki",
    email: "hittarth911@gmail.com",
    phone: "+91 9756389851",
    purchaseDate: "14/03/2024",
    amount: "2890.3",
    paymentStatus: "Payment Completed",
  },
];


/* DASHBOARD ANALYTICS */
const analytics = [
  {
    id: 1,
    label: "Total Sales",
    value: "₹10,000.00",
    growth: true,
    tileLabel: "10% growth than last month",
  },
  {
    id: 2,
    label: "Average Order",
    value: "₹1,400.00",
    growth: false,
    tileLabel: "25% less avg. order value than last month",
  },
  {
    id: 3,
    label: "Total Orders",
    value: "10",
    growth: false,
    tileLabel: "10% less orders than last quarter",
  },
];

/* CLICK OUTSIDE HANDLER */
const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const Dashboard = () => {
  /* DATEPICKER VARIABES */
  const [datepicker, setDatepicker] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date().setMonth(new Date().getMonth() - 1)).format("DD/MM/YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("DD/MM/YYYY")
  );

  let domNode = useClickOutside(() => {
    setDatepicker(false);
  });

  const [selectedTime, setSelectedTime] = useState(7);

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  /* DASHBOARD TILE */
  const DashboardTile = (props) => {
    return (
      <div className="bg-white border border-authBorder rounded-md py-5 px-8 mt-3 relative">
        <h5 className="text-lg font-proxima-semibold text-greyText">
          {props.label}
        </h5>
        <h2 className="text-3xl font-proxima-bold text-black mt-1">
          {props.value}
        </h2>
        <div className="w-2/5 absolute top-2 right-4">
          {props.growth ? (
            <p className="text-sm text-end font-proxima-semibold text-green-500">
              {props.tileLabel}
            </p>
          ) : (
            <p className="text-sm text-end font-proxima-semibold text-red-500">
              {props.tileLabel}
            </p>
          )}
        </div>
      </div>
    );
  };

  /* COLUMNS */
  const columns = [
    {
      name: "Order ID",
      width: "10vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row.orderID}
        </span>
      ),
    },
    {
      name: "Product",
      width: "30vh",
      selector: (row) => (
        <span className="text-sm font-proxima-bold text-black">
          {row.product}
        </span>
      ),
    },

    {
      name: "Customer Email",
      width: "25vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row.email}
        </span>
      ),
    },
    {
      name: "Customer Name",
      width: "18vh",
      selector: (row) => (
        <span className="text-sm font-proxima-semibold text-black text-opacity-60">
          {row.name}
        </span>
      ),
    },
    {
      name: "Amount",
      width: "10vh",
      selector: (row) => (
        <span className="text-sm font-proxima-bold text-black">
          ₹{parseFloat(row.amount).toLocaleString("en-IN")}
        </span>
      ),
    },
    {
      name: "Actions",
      width: "10vh",
      selector: (row) => (
        <div className="flex items-center">
          <Tooltip
            content="View order details"
            className="text-sm font-proxima-semibold"
          >
            <Button className="p-2 rounded-full shadow-none hover:shadow-none bg-transparent hover:bg-black hover:bg-opacity-5">
              <IoEyeSharp className="w-5 h-5 text-greyText" />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10">
      {/* PAGE TITLE */}
      <Helmet>
        <title>Dashboard | Fashion N Fun</title>
      </Helmet>

      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
              Customer Dashboard
            </h1>
            <h2 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText">
              Have a glimpse of the analytics of your customer
            </h2>
          </div>

          {/* RANGEPICKER */}
          <div className="w-full xl:w-auto lg:w-auto flex items-center gap-2 mt-3 xl:mt-0 lg:mt-0">
            <div className="relative w-full">
              <div
                ref={domNode}
                className="w-full xl:w-rangePicker lg:w-rangePicker flex items-center gap-3 border border-authBorder rounded-lg py-3 px-5 bg-white cursor-pointer"
              >
                <FaCalendar className="w-4 h-4 text-greyText" />
                <input
                  type="text"
                  name="dateRange"
                  placeholder="Select the date range"
                  value={`${startDate} - ${endDate}`}
                  onClick={() => setDatepicker(!datepicker)}
                  className="w-full bg-white outline-none font-proxima-semibold text-sm focus:outline-none active:outline-none placeholder:font-proxima-regular transition-all duration-300 ease-linear text-black"
                />
              </div>
              {datepicker && (
                <div className="absolute top-12 right-0 transition-all duration-300 ease-linear z-50">
                  <DateRangePicker
                    ranges={[selectionRange]}
                    dragSelectionEnabled
                    showPreview
                    onChange={(e) => {
                      setStartDate(
                        moment(e.selection.startDate).format("DD/MM/YYYY")
                      );
                      setEndDate(
                        moment(e.selection.endDate).format("DD/MM/YYYY")
                      );
                    }}
                    className="shadow-xl font-proxima-regular rounded-xl border border-authBorder cursor-pointer"
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-y-5 xl:gap-5 lg:gap-5 mt-3">
          <div className="col-span-1">
            {analytics.map((data) => (
              <DashboardTile
                label={data.label}
                value={data.value}
                growth={data.growth}
                tileLabel={data.tileLabel}
              />
            ))}
          </div>

          <div className="col-span-1">
            <div className="col-span-2 xl:col-span-1 lg:col-span-1 mt-3">
              <div className="bg-white border border-authBorder rounded-md">
                <div className="p-5 border-b border-authBorder">
                  <div className="flex items-center justify-between">
                    <h5 className="text-lg font-proxima-bold text-black">
                      Top selling categories
                    </h5>
                  </div>
                </div>
                <div className="p-2 mx-auto">
                  <PieChartForTopSellingCategory />
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-2">
            <div className="bg-white border border-authBorder rounded-md mt-3">
              <div className="px-5 py-3 border-b border-authBorder">
                <div className="block xl:flex lg:flex items-center justify-between">
                  <h5 className="text-lg font-proxima-bold text-black">
                    Sales Statistics
                  </h5>
                  {/* STATUS DROPDOWN */}
                  <div className="flex items-center mt-2 xl:mt-0 lg:mt-0">
                    <Button
                      className={`shadow-none hover:shadow-none text-sm font-proxima-semibold py-2 px-3 whitespace-nowrap xl:whitespace-normal lg:whitespace-normal ${selectedTime === 7
                          ? "bg-logoYellow text-white"
                          : "bg-white hover:bg-black hover:bg-opacity-5 text-black"
                        } rounded-md transition-all duration-300 ease-in-out normal-case`}
                      onClick={() => setSelectedTime(7)}
                    >
                      Last 7 days
                    </Button>
                    <Button
                      className={`shadow-none hover:shadow-none text-sm font-proxima-semibold py-2 px-3 whitespace-nowrap xl:whitespace-normal lg:whitespace-normal ${selectedTime === 30
                          ? "bg-logoYellow text-white"
                          : "bg-white hover:bg-black hover:bg-opacity-5 text-black"
                        } rounded-md transition-all duration-300 ease-in-out normal-case`}
                      onClick={() => setSelectedTime(30)}
                    >
                      Last 30 days
                    </Button>
                    <Button
                      className={`shadow-none hover:shadow-none text-sm font-proxima-semibold py-2 px-3 whitespace-nowrap xl:whitespace-normal lg:whitespace-normal ${selectedTime === 60
                          ? "bg-logoYellow text-white"
                          : "bg-white hover:bg-black hover:bg-opacity-5 text-black"
                        } rounded-md transition-all duration-300 ease-in-out normal-case`}
                      onClick={() => setSelectedTime(60)}
                    >
                      Last 60 days
                    </Button>
                  </div>
                </div>
              </div>
              <div className="p-2">
                <SplineChart />
              </div>
            </div>
          </div>
        </div>

        {/* RECENT ORDERS AND TOP SELLING PRODUCTS */}
        <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-y-5 xl:gap-5 lg:gap-5 mt-5">
          {/* RECENT ORDERS */}
          <div className="col-span-2">
            <div className="bg-white border border-authBorder rounded-md">
              <div className="p-5 border-b border-authBorder">
                <h5 className="text-lg font-proxima-bold text-black">
                  Recent Orders
                </h5>
              </div>
              <div className="p-5">
                <DataTable
                  className="data-table"
                  data={orders}
                  columns={columns}
                  responsive
                  pagination
                  paginationTotalRows={15}
                  noDataComponent={
                    <div className="w-full bg-[#F5F5F5] bg-opacity-50 rounded-md border border-authBorder py-5">
                      <p className="text-sm font-proxima-semibold text-black text-center">
                        There are no orders to display.
                      </p>
                    </div>
                  }
                />
              </div>
            </div>
          </div>

          {/* TOP SELLING PRODUCTS */}
          <div className="col-span-1">
            <div className="bg-white border border-authBorder rounded-md h-full">
              <div className="p-5 border-b border-authBorder">
                <h5 className="text-lg font-proxima-bold text-black">
                  Top Selling Products
                </h5>
              </div>
              <div className="p-5">
                <div className="grid grid-cols-3 gap-5">
                  <div className="col-span-2">
                    <div className="flex items-center gap-4">
                      <div className="w-16 h-16 bg-[#F5F5F5] rounded-md flex-shrink-0 flex items-center justify-center p-2">
                        <img
                          src="https://food-farmacy.web.app/static/media/01.77836b00b8e4d6428956.jpg"
                          className="w-[90%] h-full object-cover"
                          alt="Product Name"
                        />
                      </div>
                      <div>
                        <h5 className="text-base font-proxima-semibold text-black">
                          Blue Diamond Almonds
                        </h5>
                        <p className="text-sm font-proxima-regular text-greyText">
                          ₹1,999.00
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 flex items-center justify-end">
                    <div className="mt-2">
                      <h5 className="text-base font-proxima-semibold text-black">
                        ₹51,974.00
                      </h5>
                      <p className="text-sm font-proxima-regular text-greyText">
                        26 sold
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-5 mt-5">
                  <div className="col-span-2">
                    <div className="flex items-center gap-4">
                      <div className="w-16 h-16 bg-[#F5F5F5] rounded-md flex-shrink-0 flex items-center justify-center p-2">
                        <img
                          src="https://food-farmacy.web.app/static/media/01.77836b00b8e4d6428956.jpg"
                          className="w-[90%] h-full object-cover"
                          alt="Product Name"
                        />
                      </div>
                      <div>
                        <h5 className="text-base font-proxima-semibold text-black">
                          Blue Diamond Almonds
                        </h5>
                        <p className="text-sm font-proxima-regular text-greyText">
                          ₹1,999.00
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 flex items-center justify-end">
                    <div className="mt-2">
                      <h5 className="text-base font-proxima-semibold text-black">
                        ₹51,974.00
                      </h5>
                      <p className="text-sm font-proxima-regular text-greyText">
                        26 sold
                      </p>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-5 mt-5">
                  <div className="col-span-2">
                    <div className="flex items-center gap-4">
                      <div className="w-16 h-16 bg-[#F5F5F5] rounded-md flex-shrink-0 flex items-center justify-center p-2">
                        <img
                          src="https://food-farmacy.web.app/static/media/01.77836b00b8e4d6428956.jpg"
                          className="w-[90%] h-full object-cover"
                          alt="Product Name"
                        />
                      </div>
                      <div>
                        <h5 className="text-base font-proxima-semibold text-black">
                          Blue Diamond Almonds
                        </h5>
                        <p className="text-sm font-proxima-regular text-greyText">
                          ₹1,999.00
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1 flex items-center justify-end">
                    <div className="mt-2">
                      <h5 className="text-base font-proxima-semibold text-black">
                        ₹51,974.00
                      </h5>
                      <p className="text-sm font-proxima-regular text-greyText">
                        26 sold
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
