/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Editor } from "react-draft-wysiwyg";
import { Helmet } from "react-helmet";
import ReactImageUploading from "react-images-uploading";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

/* MATERIAL TAILWIND COMPONENTS */
import { Button } from "@material-tailwind/react";

/* REACT ICONS */
import { FiTrash } from "react-icons/fi";

/* TOAST NOTIFICATION */
import { toast } from "react-toast";

/* USER DEFINED COMPONENTS */
import InputField from "../../../components/input";
import { getProductDetailsById } from "../../../service/api";

const ViewProduct = () => {
  const { id } = useParams();
  // STATE VARIABLES
  const [loading, setLoading] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const [category, setCategory] = useState();
  console.log("🚀 ~ ViewProduct ~ category:", category)
  const [productImages, setProductImages] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  /* CHOOSE IMAGES */
  const onImageChange = (imageList) => {
    setProductImages(imageList);
  };

  /* HANDLE DELETE DATA */
  const handleGetProductDetails = () => {
    getProductDetailsById(id)
      .then((res) => {
        toast.success(res?.message);
        setProductDetails(res?.data);
        setCategory(res?.data?.category);
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleGetProductDetails();
  }, [id]);

  return (
    <section className="px-0 py-2 xl:p-10 lg:p-10 min-h-screen xl:min-h-full lg:min-h-full">
      {/* PAGE TITLE */}
      <Helmet>
        <title>View Product | Fashion N Fun</title>
      </Helmet>

      <div className="container-full-width">
        {/* HEADING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-proxima-bold text-black tracking-tight">
              View Product
            </h1>
            <h2 className="text-base xl:text-lg lg:text-lg font-proxima-regular text-greyText tracking-tight">
              Fill in the following details to add the product in the inventory
            </h2>
          </div>
          <div className="flex items-center gap-2 mt-2 xl:mt-0 lg:mt-0">
            <Link to="/inventory">
              <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-white hover:bg-black hover:bg-opacity-5 px-5 py-3 rounded-md border border-authBorder text-sm font-proxima-semibold text-black normal-case transition-all duration-300 ease-in-out">
                Back to Inventory
              </Button>
            </Link>
            {/* <Button className="w-full xl:w-auto lg:w-auto whitespace-nowrap xl:whitespace-normal lg:whitespace-normal shadow-none hover:shadow-none bg-logoYellow hover:bg-opacity-80 px-5 py-3 rounded-md border border-logoYellow text-sm font-proxima-semibold text-white normal-case transition-all duration-300 ease-in-out">
              Save details
            </Button> */}
          </div>
        </div>

        {/* FORM */}
        <div className="mt-5">
          <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-y-5 xl:gap-5 lg:gap-5">
            {/* DETAILS */}
            <div className="col-span-2">
              <div className="bg-white rounded-lg border border-authBorder">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-lg font-proxima-bold text-black">
                    Product Details
                  </h5>
                  <p className="text-sm font-proxima-regular text-greyText">
                    View these product specific details
                  </p>
                </div>
                <div className="p-5">
                  <form>
                    <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Product Name <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="productName"
                              id="productName"
                              disabled={true}
                              value={productDetails?.name}
                              placeholder="Write the product name"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Product SKU <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="text"
                              name="productSKU"
                              id="productSKU"
                              disabled={true}
                              value={productDetails?.sku}
                              placeholder="Write the product sku"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Product Price (₹){" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="number"
                              name="productPrice"
                              id="productPrice"
                              disabled={true}
                              value={productDetails?.amount}
                              placeholder="Write the product price"
                            />
                          </div>
                        </div>
                      </div> */}
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Stock in inventory{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <InputField
                              type="number"
                              name="stock"
                              id="stock"
                              disabled={true}
                              value={productDetails?.quantity.length}
                              placeholder="Write the stock in inventory"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Product Category{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <Select
                              // options={category.map((item)=> item.name)}
                              classNamePrefix="dropdown"
                              placeholder="Select category"
                              name="productCategory"
                              id="productCategory"
                              // value={category.map((item)=> item.name)}
                              isDisabled={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 xl:col-span-1 lg:col-span-1">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Product Sub-category{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <Select
                              options={[]}
                              classNamePrefix="dropdown"
                              placeholder="Select sub-category"
                              name="subCategory"
                              id="subCategory"
                              isDisabled={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Product Tags <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <Select
                              options={[]}
                              isMulti
                              classNamePrefix="dropdown"
                              placeholder="Select tags"
                              name="tags"
                              isDisabled={true}
                              id="tags"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2">
                        <div className="form-group">
                          <label className="text-sm font-proxima-semibold text-black">
                            Product Description{" "}
                            <span className="text-red-500">*</span>
                          </label>
                          <div className="mt-1">
                            <Editor
                              toolbar={{ options: ["inline", "link"] }}
                              editorClassName="text-editor"
                              wrapperClassName="text-editor-wrapper"
                              placeholder={productDetails?.description}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* IMAGES AND DISCOUNTS */}
            <div className="col-span-1">
              {/* PRODUCT IMAGES */}
              <div className="bg-white rounded-lg border border-authBorder">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-lg font-proxima-bold text-black">
                    Product Images
                  </h5>
                  <p className="text-sm font-proxima-regular text-greyText">
                    Attach the product images for this product
                  </p>
                </div>
                <div className="p-5">
                  <ReactImageUploading
                    multiple
                    value={productImages}
                    onChange={onImageChange}
                    maxNumber={5}
                    dataURLKey="data_url"
                  >
                    {({ imageList, onImageUpload, onImageRemove }) => (
                      <div className="grid grid-cols-2 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                        <div className="col-span-1">
                          <div
                            className="w-full h-productImage rounded-md bg-[#F5F5F5] bg-opacity-50 border border-authBorder cursor-pointer hover:border-logoYellow transition-all duration-300 ease-in-out p-5"
                            onClick={onImageUpload}
                          >
                            {/* <AiFillPlusCircle className="w-8 h-8 text-greyText mx-auto" /> */}
                            <div className="mt-2">
                              <p className="text-s font-proxima-semibold text-greyText text-center">
                                No Images found
                              </p>
                              {/* <p className="text-xs font-proxima-regular text-greyText text-center mt-1">
                                (JPG, JPEG, PNG)
                              </p> */}
                            </div>
                          </div>
                          {/* {
                            [data: products[{}, {image:}]]
                          } */}
                        </div>
                        {imageList.map((image, index) => (
                          <div className="col-span-1 relative" key={index}>
                            <img
                              src={image["data_url"]}
                              className="w-full h-productImage object-cover rounded-md"
                              alt="Product"
                            />
                            <div className="absolute top-2 right-2">
                              <Button
                                className="p-2 rounded-full shadow-none hover:shadow-none bg-white border border-logoYellow"
                                onClick={() => onImageRemove(index)}
                              >
                                <FiTrash className="w-3 h-3 text-black" />
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ReactImageUploading>
                </div>
              </div>

              {/* SPECIAL DISCOUNTS */}
              <div className="mt-5 bg-white rounded-lg border border-authBorder">
                <div className="px-5 py-3 border-b border-authBorder">
                  <h5 className="text-lg font-proxima-bold text-black">
                    Special Discounts
                  </h5>
                  <p className="text-sm font-proxima-regular text-greyText">
                    Enter the special price as well as the start date and end
                    date
                  </p>
                </div>
                <div className="p-5">
                  <div className="form-group">
                    <label className="text-sm font-proxima-semibold text-black">
                      Special Price
                    </label>
                    <div className="mt-1">
                      <InputField
                        type="number"
                        name="specialPrice"
                        id="specialPrice"
                        disabled={true}
                        placeholder="Write the special price"
                      />
                    </div>
                  </div>
                  <div className="mt-5 grid grid-cols-2 gap-5">
                    <div className="col-span-1">
                      <div className="form-group">
                        <label className="text-sm font-proxima-semibold text-black">
                          Start Date
                        </label>
                        <div className="mt-1">
                          <ReactDatePicker
                            disabled
                            placeholderText="Select Date"
                            calendarClassName="calendar"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="form-group">
                        <label className="text-sm font-proxima-semibold text-black">
                          End Date
                        </label>
                        <div className="mt-1">
                          <ReactDatePicker
                            disabled
                            placeholderText="Select Date"
                            calendarClassName="calendar"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewProduct;
